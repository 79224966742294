export default (state, action) => {
  

    if(action.type==="save_user_account_to_store"){
        let new_state = Object.assign({}, state, ) 
        new_state.user_account=action.payload
        
        return new_state
    }
  
  
    else{ return state}
  
  
  
};