import { createStore } from 'redux'
import rotateReducer from './Reducers';
import { composeWithDevTools } from 'redux-devtools-extension';


let state= {  

                user_account:null,
            
              
            };



function configureStore() {
  return createStore(rotateReducer,
                       state, 
                       composeWithDevTools() );
}

export default configureStore;