

 const years=    [   '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010',
                          '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000'
                      ];



const subjects= [
                        {subject_description:'Mathematics', subject_code:"mathematics"	},	                
                        {subject_description:'Mathematical Literacy', subject_code:"mathematical_literacy"	},                    
                        {subject_description:'Life Sciences', subject_code:"life_sciences"	},                       
                        {subject_description:'Physical Sciences', subject_code:"physical_sciences"	},                       
                        {subject_description:'History', subject_code:"history"	},                       
                        {subject_description:'Business Studies', subject_code:"business_studies"	},                       
                        {subject_description:'Accounting', subject_code:"accounting"	},                      
                        {subject_description:'Economics', subject_code:"economics"	},                     
                        {subject_description:'Agricultural Science', subject_code:"agricultural_science"	},                                           
        
                 ]  ;


                

const mathematics_topics=[
                                  {topic_description:'Algebraic Expressions', topic_code:'algebraic_expressions'},
                                  {topic_description:'Exponents and Surds', topic_code:'exponents_and_surds'},
                                  {topic_description:'Equations and Inequalities', topic_code:'equations_and_inequalities'},
                                  {topic_description:'Trigonometry', topic_code:'trigonometry'},
                                  {topic_description:'Functions', topic_code:'functions'},
                                  {topic_description:'Trigonometric Functions', topic_code:'trigonometric_functions'},
                                  {topic_description:'Analytical Geometry', topic_code:'analytical_geometry'},
                                  {topic_description:'Algebraic Expressions and Equations (and Inequalities)', topic_code:'algebraic_expressions_and_equations_and_inequalities'},
                                  {topic_description:'Number Patterns, Sequences and Series', topic_code:'Number_patterns_sequences_and_series'},
                                  {topic_description:'Finance, Growth and Decay', topic_code:'finance_growth_and_decay'},
                                  {topic_description:'Functions:Polynomials', topic_code:'function_polynomials'},
                                  {topic_description:'Differential Calculus', topic_code:'differential_calculus'},
                                  {topic_description:'Counting and Probability', topic_code:'counting_and_probability'},
                                  {topic_description:'Euclidean Geometry and Measurement', topic_code:'euclidean_geometry_and_measurement'},
                                  {topic_description:'Statistics(Regression and Correlation)', topic_code:'Statistics_regression_and_correlation'},
                                  {topic_description:'Functions : Polynomials', topic_code:'Function_polynomials'},
                    ];

const mathematical_literacy_topics=[
                                    {topic_description:'Finance', topic_code:'finance'},
                                    {topic_description:'Measurement', topic_code:'measurement'},
                                    {topic_description:'Maps, Plans and Other Representations of the Physical World', topic_code:'maps_plans_and_other_representations_of_the_physical_world'},
                                    {topic_description:'Data Handling', topic_code:'data_handling'},
                                    {topic_description:'Probability', topic_code:'probability'},
                                    {topic_description:'Interpreting and Communicating Answers and Calculations', topic_code:'interpreting_and_communicating_answers_and_calculations'},
                                    {topic_description:'Numbers and Calculations with Numbers', topic_code:'numbers_and_calculations_with_numbers'},
                                    {topic_description:'Rounding', topic_code:'rounding'},
                                    {topic_description:'Ratios', topic_code:'ratios'},
                                    {topic_description:'Proportion', topic_code:'proportion'},
                                    {topic_description:'Rates', topic_code:'rates'},
                                    {topic_description:'Percentages', topic_code:'percentages'},
                                    {topic_description:'Number Formats and Conventions', topic_code:'number_formats_and_conventions'},
                                    {topic_description:'Patterns, Relationships and Representations', topic_code:'patterns_relationships_and_representations'},
                                    {topic_description:'Data Handling', topic_code:'Data Handling'}
            ]  ;              


const life_sciences_topics = [
                    {topic_description:'Chemistry Of Life', topic_code:'chemistry_of_life'},
                    {topic_description:'Inorganic Compounds', topic_code:'inorganic_compounds'},
                    {topic_description:'Organic Compounds', topic_code:'organic_compounds'},
                    {topic_description:'Cells: Basic Units Of Life', topic_code:'cells_basic_units_of_life'},
                    {topic_description:'Cell Division: Mitosis', topic_code:'cell_division_mitosis'},
                    {topic_description:'Plant And Animal Tissues', topic_code:'plant_and_animal_tissues'},
                    {topic_description:'Support And Transport Systems: Plants (Transpiration)', topic_code:'support_and_transport_systems_plants_transpiration'},
                    {topic_description:'Support Systems: Animals (Skeletons)', topic_code:'support_systems_animals_skeletons'},
                    {topic_description:'Transport Systems In Mammals (Circulatory System)', topic_code:'transport_systems_in_mammals_circulatory_system'},
                    {topic_description:'Biosphere To Ecosystems', topic_code:'biosphere_to_ecosystems'},
                    {topic_description:'Biodiversity And Classification', topic_code:'biodiversity_and_classification'},
                    {topic_description:'History Of Life And Earth', topic_code:'history_of_life_and_earth'},
                    {topic_description:'Energy Transformations To Sustain Life: Photosynthesis', topic_code:'energy_transformations_to_sustain_life_photosynthesis'},
                    {topic_description:'Animal Nutrition', topic_code:'animal_nutrition'},
                    {topic_description:'Energy Transformation: Respiration', topic_code:'energy_transformation_respiration'},
                    {topic_description:'Gaseous Exchange', topic_code:'gaseous_exchange'},
                    {topic_description:'Excretion', topic_code:'excretion'},
                    {topic_description:'Population Ecology', topic_code:'population_ecology'},
                    {topic_description:'Meiosis', topic_code:'meiosis'},
                    {topic_description:'Reproduction In Animals', topic_code:'reproduction_in_animals'},
                    {topic_description:'Human Reproduction', topic_code:'human_reproduction'},
                    {topic_description:'Responding To Be Environment (Humans)', topic_code:'responding_to_be_environment_humans'},
                    {topic_description:'Nervous Systems', topic_code:'nervous_systems'},
                    {topic_description:'Senses', topic_code:'senses'},
                    {topic_description:'Endocrine System', topic_code:'endocrine_system'},
                    {topic_description:'Homeostasis', topic_code:'homeostasis'},
                    {topic_description:'DNA: Code Of Life', topic_code:'dna_code_of_life'},
                    {topic_description:'RNA And Protein Synthesis', topic_code:'rna_and_protein_synthesis'},
                    {topic_description:'Evolution Through Natural Selection', topic_code:'evolution_through_natural_selection'},
                    {topic_description:'Human Evolution', topic_code:'human_evolution'},
                    {topic_description:'Human Impact On The Environment: Current Crises', topic_code:'human_impact_on_the_environment_current_crises'},
                    
]                
             

const physical_sciences_topics =[
                            {topic_description:'Vectors & Scalars', topic_code:'vectors_and_scalars'},
                            {topic_description:'Motion', topic_code:'motion'},
                            {topic_description:'Energy', topic_code:'energy'},
                            {topic_description:'Vectors In Two Dimensions', topic_code:'vectors_in_two_dimensions'},
                            {topic_description:'Newton’s Laws And Application Of Newton’s Laws', topic_code:'newton’s_laws_and_application_of_newton’s_laws'},
                            {topic_description:'Momentum And Impulse', topic_code:'momentum_and_impulse'},
                            {topic_description:'Vertical Projectile Motion In One Dimension', topic_code:'vertical_projectile_motion_in_one_dimension'},
                            {topic_description:'Work, Energy & Power', topic_code:'work_energy_and_power'},
                            {topic_description:'Transverse Pulses On A String Or Spring', topic_code:'transverse_pulses_on_a_string_or_spring'},
                            {topic_description:'Transverse And Longitudinal Waves', topic_code:'transverse_and_longitudinal_waves'},
                            {topic_description:'Electromagnetic Radiation', topic_code:'electromagnetic_radiation'},
                            {topic_description:'Geometrical Optics', topic_code:'geometrical_optics'},
                            {topic_description:'2D & 3D Wave Fronts', topic_code:'2d_and_3d_wave_fronts'},
                            {topic_description:'Doppler Effect', topic_code:'doppler_effect'},
                            {topic_description:'Magnetism', topic_code:'magnetism'},
                            {topic_description:'Electric Circuits', topic_code:'electric_circuits'},
                            {topic_description:'Electrostatics', topic_code:'electrostatics'},
                            {topic_description:'Electromagnetism', topic_code:'electromagnetism'},
                            {topic_description:'Electrodynamics', topic_code:'electrodynamics'},
                            {topic_description:'Matter And Classification', topic_code:'matter_and_classification'},
                            {topic_description:'Atomic Structure', topic_code:'atomic_structure'},
                            {topic_description:'Periodic Table', topic_code:'periodic_table'},
                            {topic_description:'Chemical Bonding', topic_code:'chemical_bonding'}, 
                            {topic_description:'Composition Of Particles Substances', topic_code:'composition_of_particles_substances'},
                            {topic_description:'Molecular Structure', topic_code:'molecular_structure'},
                            {topic_description:'Intermolecular Forces', topic_code:'intermolecular_forces'}, 
                            {topic_description:'Ideal Gases', topic_code:'ideal_gases'}, 
                            {topic_description:'Optical Phenomena And Properties Of Materials', topic_code:'optical_phenomena_and_properties_of_materials'}, 
                            {topic_description:'Organic Chemistry', topic_code:'organic_chemistry'}, 
                            {topic_description:'Organic Macromolecules', topic_code:'organic_macromolecules'},
                            {topic_description:'Hydrosphere', topic_code:'hydrosphere'},
                            {topic_description:'Lithosphere', topic_code:'lithosphere'},
                            {topic_description:'Chemical Industry (Fertilizer Industry)', topic_code:'chemical_industry_fertilizer_industry'},
                            {topic_description:'Physical And Chemical Change', topic_code:'physical_and_chemical_change'}, 
                            {topic_description:'Representing Chemical Change', topic_code:'representing_chemical_change'},
                            {topic_description:'Reactions In Aqueous Solution', topic_code:'reactions_in_aqueous_solution'},
                            {topic_description:'Stoichiometry', topic_code:'stoichiometry'},
                            {topic_description:'Energy And Chemical Change', topic_code:'energy_and_chemical_change'},
                            {topic_description:'Types Of Reactions (Acid-Base; Redox Reactions; Oxidation Numbers)', topic_code:'types_of_reactions_acid-base_redox_reactions_oxidation_numbers'},
                            {topic_description:'Reaction Rate', topic_code:'reaction_rate'}, 
                            {topic_description:'Chemical Equilibrium', topic_code:'chemical_equilibrium'},
                            {topic_description:'Acids And Bases', topic_code:'acids_and_bases'},
                            {topic_description:'Electrochemical Reactions', topic_code:'electrochemical_reactions'},
                            
]   


const history_topics = [
                        {topic_description:'The World Around 1600', topic_code:'the_world_around_1600'},
                        {topic_description:'Expansion And Conquest During The 15th -18th Centuries', topic_code:'expansion_and_conquest_during_the_15th_-18th_centuries'},
                        {topic_description:'The French Revolution', topic_code:'the_french_revolution'},
                        {topic_description:'Transformations In Southern Africa After 1750', topic_code:'transformations_in_southern_africa_after_1750'},
                        {topic_description:'Colonial Expansion After 1750', topic_code:'colonial_expansion_after_1750'},
                        {topic_description:'The South African War And Union', topic_code:'the_south_african_war_and_union'},
                        {topic_description:'Communism In Russia 1900 To1940', topic_code:'communism_in_russia_1900_to1940'},
                        {topic_description:'Capitalism And The USA 1900 To 1940', topic_code:'capitalism_and_the_usa_1900_to_1940'},
                        {topic_description:'Ideas Of Race In The Late 19th And 20th Centuries', topic_code:'ideas_of_race_in_the_late_19th_and_20th_centuries'},
                        {topic_description:'Nationalisms: South Africa, The Middle East And Africa', topic_code:'nationalisms:_south_africa,_the_middle_east_and_africa'},
                        {topic_description:'Apartheid In South Africa 1940s To 1960s', topic_code:'apartheid_in_south_africa_1940s_to_1960s'},
                        {topic_description:'The Cold War', topic_code:'the_cold_war'},
                        {topic_description:'Independent Africa', topic_code:'independent_africa'},
                        {topic_description:'Civil Society Protests 1950s To 1990s', topic_code:'civil_society_protests_1950s_to_1990s'},
                        {topic_description:'Civil Resistance 1970s To 1980s In South Africa', topic_code:'civil_resistance_1970s_to_1980s_in_south_africa'},
                        {topic_description:'The Coming Of Democracy In South Africa, And Coming To Terms With The Past', topic_code:'the_coming_of_democracy_in_south_africa,_and_coming_to_terms_with_the_past'},
                        {topic_description:'The End Of The Cold War And A New Global World Order 1989 To Present.', topic_code:'the_end_of_the_cold_war_and_a_new_global_world_order_1989_to_present.'},
                        
]         


const business_studies_topics =[
                        {topic_description:'Micro, Market And Macro Environments', topic_code:'micro_market_and_macro_environments'},
                        {topic_description:'Business Sectors', topic_code:'business_sectors'},
                        {topic_description:'Contemporary Socioeconomic Issues', topic_code:'contemporary_socioeconomic_issues'},
                        {topic_description:'Entrepreneurship', topic_code:'entrepreneurship'},
                        {topic_description:'Business Opportunity And Related Factors', topic_code:'business_opportunity_and_related_factors'},
                        {topic_description:'Business Plan', topic_code:'business_plan'},
                        {topic_description:'Management And Leadership', topic_code:'management_and_leadership'},
                        {topic_description:'Forms Of Ownership', topic_code:'forms_of_ownership'},
                        {topic_description:'Setting Up A Business', topic_code:'setting_up_a_business'},
                        {topic_description:'Contracts', topic_code:'contracts'},
                        {topic_description:'Business Location', topic_code:'business_location'},
                        {topic_description:'Investment: Securities And Insurance', topic_code:'investment_securities_and_insurance'},
                        {topic_description:'Presentation Of Business Information', topic_code:'presentation_of_business_information'},
                        {topic_description:'Creative Thinking And Problem-Solving', topic_code:'creative_thinking_and_problem-solving'},
                        {topic_description:'Self-Management, Professionalism And Ethics', topic_code:'self-management_professionalism_and_ethics'},
                        {topic_description:'Human Rights, Inclusivity And Environmental Issues.', topic_code:'human_rights_inclusivity_and_environmental_issues'},
                        {topic_description:'Social Responsibility', topic_code:'social_responsibility'},
                        {topic_description:'Stress, Crisis, Change And Conflict Management', topic_code:'stress_crisis_change_and_conflict_management'},
                        {topic_description:'Relationship And Team Performance', topic_code:'relationship_and_team_performance'},
                        {topic_description:'Business Functions', topic_code:'business_functions'},
                        {topic_description:'Quality Of Performance', topic_code:'quality_of_performance'},
                        

]       


const accounting_topics= [
                    {topic_description:'Accounting Concepts', topic_code:'accounting_concepts'},
                    {topic_description:'GAAP Principles', topic_code:'gaap_principles'},
                    {topic_description:'Bookkeeping', topic_code:'bookkeeping'},
                    {topic_description:'Accounting Equation', topic_code:'accounting_equation'},
                    {topic_description:'Final Accounts And Financial Statements', topic_code:'final_accounts_and_financial_statements'},
                    {topic_description:'Salaries And Wages', topic_code:'salaries_and_wages'},
                    {topic_description:'Value-Added Tax', topic_code:'value_added_tax'},
                    {topic_description:'Reconciliations', topic_code:'reconciliations'},
                    {topic_description:'Cost Accounting', topic_code:'cost_accounting'},
                    {topic_description:'Budgeting', topic_code:'budgeting'},
                    {topic_description:'Indigenous Bookkeeping Systems', topic_code:'indigenous_bookkeeping_systems'},
                    {topic_description:'Fixed Assets', topic_code:'fixed_assets'},
                    {topic_description:'Inventory', topic_code:'inventory'},
                    {topic_description:'Ethics', topic_code:'ethics'},
                    {topic_description:'Internal Control', topic_code:'internal_control'},
                    
]                


const economics_topics = [
                        {topic_description:'Economics: Basic Concepts', topic_code:'economics_basic_concepts'},
                        {topic_description:'Basic Economic Problem', topic_code:'basic_economic_problem'},
                        {topic_description:'Circular Flow', topic_code:'circular_flow'},
                        {topic_description:'Quantitative Elements', topic_code:'quantitative_elements'},
                        {topic_description:'Economic Systems', topic_code:'economic_systems'},
                        {topic_description:'Business Cycles', topic_code:'business_cycles'},
                        {topic_description:'Dynamics Of Markets', topic_code:'dynamics_of_markets'},
                        {topic_description:'Public Sector Intervention And Composition Of The RSA Economy', topic_code:'public_sector_intervention_and_composition_of_the_rsa_economy'},
                        {topic_description:'Economic Growth And Development', topic_code:'economic_growth_and_development'},
                        {topic_description:'Money And Banking', topic_code:'money_and_banking'},
                        {topic_description:'Population And Labour Force', topic_code:'population_and_labour_force'},
                        {topic_description:'Labour Market', topic_code:'labour_market'},
                        {topic_description:'Redress Since 1994', topic_code:'redress_since_1994'},
                        {topic_description:'Unemployment', topic_code:'unemployment'},
                        {topic_description:'Labour Relations', topic_code:'labour_relations'},
                        {topic_description:'Globalisation', topic_code:'globalisation'},
                        {topic_description:'Inflation', topic_code:'inflation'},
                        {topic_description:'Tourism', topic_code:'tourism'},
                        {topic_description:'Poverty', topic_code:'poverty'},
                        {topic_description:'Other Economic Issues And Quantitative Elements Of Economics', topic_code:'other_economic_issues_and_quantitative_elements_of_economics'},
                        

]                


const agricultural_science_topics = [
                {topic_description:'Soil Science', topic_code:'soil_science'},
                {topic_description:'Plant Studies', topic_code:'plant_studies'},
                {topic_description:'Animal Studies', topic_code:'animal_studies'},
                {topic_description:'Agricultural Economics', topic_code:'agricultural_economics'},
                {topic_description:'Basic Agricultural Chemistry', topic_code:'basic_agricultural_chemistry'},
                {topic_description:'Basic Genetics And Biological Concepts', topic_code:'basic_genetics_and_biological_concepts'},
                {topic_description:'Sustainable Natural Resource Utilization', topic_code:'sustainable_natural_resource_utilization'},
                {topic_description:'Agro-Ecology', topic_code:'agro_ecology'},        
                    
]              






 export {
            years, subjects,  mathematics_topics, mathematical_literacy_topics, life_sciences_topics, physical_sciences_topics,
            history_topics, business_studies_topics, accounting_topics, economics_topics, agricultural_science_topics,  
         };