// App.js
import React, { Component } from 'react';
import { Route, Redirect, withRouter, Link, Switch, BrowserRouter as Router } from 'react-router-dom'
import Modal from 'react-modal';

import axios from 'axios';

import {save_user_account_to_store } from './Actions';

import { connect } from "react-redux";


//React-Toastify is a better alternative for react-notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { years, subjects,  mathematics_topics, mathematical_literacy_topics, life_sciences_topics, 
          physical_sciences_topics, history_topics, business_studies_topics, accounting_topics, economics_topics, 
          agricultural_science_topics,  } from './extra_script';

import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'

import CKEditor from 'ckeditor4-react';

//const base_url= "http://localhost:8000/api/";

const base_url= "https://backend.learnershub.co.za/api/";

if(base_url==="https://backend.learnershub.co.za/api/" && window.location.hostname==='localhost'){setInterval(function(){ toast("You are using Live backend"); },10000)}

const pagination_amount = 200;

toast.configure({
  autoClose: 3000,
  draggable: false,
  //etc you get the idea
});









class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  user_is_logged_in:false,
                  
                  token:'',

                  have_user_account:'no'
                  
                };
  }

  componentDidMount() {

       setInterval(this.one_second_tasks, 1000);


  }

  one_second_tasks=()=>{
    this.check_login();
    this.get_user_account_details()
  
  }

 
  check_login=()=>{

            if (localStorage.login_token !== undefined ){
              this.setState({user_is_logged_in: true});
                
            }
            else{this.setState({user_is_logged_in: false}); }
                
  }

  get_user_account_details=()=>{
      
    if(localStorage.getItem("login_token") === null){return}
   
    if(this.props.user_account!==null   ){return}    
    if(this.state.have_user_account==="pending"   ){return}    
           
     
    let url= base_url + 'user-account-info'
  
    const token = localStorage.getItem("login_token")
    let token_header= { Authorization :  'Token '+token}   

    this.setState({ have_user_account: "pending"});
    
  
    axios({
      method: 'get',
      url: url,
      data:'',
      headers:token_header
    })
    
      .then(res => {
                
           
           
            if (res.data.platform=='admin'|| res.data.platform =='sub_admin'|| res.data.platform =='image_admin'){
                 
                 this.props.save_user_account_to_store(res.data)

                 this.setState({ have_user_account: "yes"});
                 
            }
            else{alert('Yes you have an account with us, but you are not an admin. Login at the Learners or Mentors Platform')}
           

      })

      .catch((error)=> {
          // handle error
         
          console.log(error); 
          this.setState({ have_user_account: "no"});   
         
          toast("OOPs, Something went wrong")
        
      })
     


  }

  get_page=()=>{

        let page =  <Switch >
                             
                            <Route exact path="/add-new-question" component={Add_new_question} />       
                            <Route  exact path="/view-all-question" component={View_all_questions} />
                            <Route exact  path="/view-all-addendum" component={View_all_addendum} />
                            <Route exact  path="/view-all-solution" component={View_all_solution} />
                            <Route exact path="/view-all-formula" component={View_all_formula} />
                            <Route exact path="/view-all-news" component={View_all_news} />
                            <Route exact path="/add-new-news" component={Add_new_news} />
                            <Route exact path="/admin-dashboard" component={AdminDashboard} /> 
                            <Route exact path="/historical-admin-dashboard" component={HistoricalAdminDashboard} /> 
                            <Route exact path="/" component={Dashboard} /> 
                    </Switch>                
          return page

  }
  render() {


    let header_section = null
    let page=null
    if(localStorage.getItem("login_token")!==null && this.props.user_account!==null){ 
      
      header_section =<ConnectHeaderSection /> 
      page = this.get_page()
    
    
    }

    

   
    return (

              <div>
                 
                  
                  
                  <Modal isOpen={!this.state.user_is_logged_in} contentLabel="Minimal Modal Example">
                  <ConnectLogin/>            
                  </Modal>

                  {header_section}
                  {page}
                 
              </div>
     

    )
  }

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class AdminDashboard extends Component {
  

  constructor(props) {
      super(props);
      this.state = { 
                      admin_platform_data:""



                   }      

  }


  componentDidMount() {

   this.get_admin_platform_data()
  

 } 

  get_admin_platform_data=()=> {
    
           
            let url = base_url;
                url= url + 'calculate-admin-platform-data'
          
            let token = localStorage.getItem("login_token")    
            
            let token_header= { Authorization :  'Token '+token} 

            console.log(this.state.form_data)
            axios({
              method: 'get',
              url: url,
              data:"",
              headers:token_header
            })
            
              .then(res => {

                  
                    this.setState({ admin_platform_data: res.data })
                    toast("Success")
               
              })

              .catch((error)=> {
                  // handle error
                  console.log(error);
                 
                  toast("Something is wrong")
                
              })

  }

  get_admin_data_page=()=>{

      if (this.state.admin_platform_data===""){return}

      let page = <div style={{marginLeft:"5%", marginTop:"3%"}}>

                                  <h4>All User Time on Site Today : {this.state.admin_platform_data.today_time_on_app}</h4>

                                  <hr/>
                        <div >

                                <h3>Registered Users</h3>
                                <p>All Registered Users : {this.state.admin_platform_data.registration.all_users}</p>
                                <p>New Users Today : {this.state.admin_platform_data.registration.registered_today}</p>
                                <p>New Users Yesterday : {this.state.admin_platform_data.registration.registered_yesterday}</p>
                                <p>Last 7 days New Users : {this.state.admin_platform_data.registration.registered_last_7days}</p>
                                <p>Last 30 days New Users : {this.state.admin_platform_data.registration.registered_last_30days}</p>

                        </div>

                          <hr/>

                        <div>

                                <h3>Active Users</h3>
                                <p>Today Active Users : {this.state.admin_platform_data.last_login.last_login_today}</p>
                                <p>Yesterday Active Users : {this.state.admin_platform_data.last_login.last_login_yesterday}</p>
                                <p>Last 7 Days Active Users : {this.state.admin_platform_data.last_login.last_login_last_7days}</p>                               
                                <p>Last 30 days Active Users : {this.state.admin_platform_data.last_login.last_login_last_30days}</p>

                        </div>

                                <hr/>
                        <div>

                                <h3>Questions Attempted</h3>
                                <p>All Attempted Questions : {this.state.admin_platform_data.question_attempts.all_attempts}</p>
                                <p>Questions Attempted Today : {this.state.admin_platform_data.question_attempts.attempted_today}</p>
                                <p>Questions Attempted Yesterday : {this.state.admin_platform_data.question_attempts.attempted_yesterday}</p>                               
                                <p>Questions Attempted in Last 7 Days : {this.state.admin_platform_data.question_attempts.attempted_last_7days}</p>
                                <p>Questions Attempted in Last 30 Days : {this.state.admin_platform_data.question_attempts.attempted_last_30days}</p>

                        </div>
                        <hr/>






          </div>



        return page
  }

  render() {
   
    let page = this.get_admin_data_page()
  
    return (

     
     
      <div >
                <h1  style={{marginLeft:"30%",}}>Dashboard</h1>
                <button style={{marginLeft:"30%", marginTop:"1%"}}  onClick={this.get_admin_platform_data} >Refresh Data</button>
                {page}











            
      </div>
   
    );
  }

}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class HistoricalAdminDashboard extends Component {
  

  constructor(props) {
      super(props);
      this.state = { 
                     
                    historical_admin_dashboard_data:""

                   }      

  }


  componentDidMount() {

   
    this.get_historical_admin_platform_data()

 } 

 get_historical_admin_platform_data=()=> {
    
           
            let url = base_url;
                url= url + 'get-historical-admin-platform-data'
          
            let token = localStorage.getItem("login_token")    
            
            let token_header= { Authorization :  'Token '+token} 

           
            axios({
              method: 'get',
              url: url,
              data:"",
              headers:token_header
            })
            
              .then(res => {

                    this.parse_stringified_data(res.data)
                   
                    toast("Success")
               
              })

              .catch((error)=> {
                  // handle error
                  console.log(error);
                 
                  toast("Something is wrong")
                
              })

  }

  parse_stringified_data=(response_data)=>{

        
        let x = 0
     
        while(x < response_data.length){

          response_data[x].data_value = JSON.parse(response_data[x].data_value)


        x++

        }
        
        this.setState({historical_admin_dashboard_data: response_data})


  }

  get_time_on_app_today_value=(index)=>{

  
        let value
        let length = this.state.historical_admin_dashboard_data.length


      

      if (index == length-1 ){
        
        return 'No Data'
       }
     
      else{
            value = ((this.state.historical_admin_dashboard_data[index].data_value.today_time_on_app - this.state.historical_admin_dashboard_data[index+1].data_value.today_time_on_app)/3600)

            if ( isNaN(value) ){return 'No DATA'}else{return value  + " Hours"}

        

      }



  }

  get_historical_admin_data_page=()=>{

      if(this.state.historical_admin_dashboard_data ===""){return}
     
         let page =  <div style={{ marginTop:"3%"}}>
                                    <table class='table'>
                                              <thead>
                                                  <tr>
                                                    <th>S/N</th>
                                                    <th>Date</th>
                                                    <th>All Users</th>
                                                    <th>All Users Time on Platform Today</th>
                                                    <th>Today's New Users</th>
                                                    <th>Yesterday's New Users</th>
                                                    <th>Last 7 Days New Users </th>
                                                    <th>Last 30 Days New Users</th>
                                                    <th>Today's Active Users</th>
                                                    <th>Yesterday's Active Users</th>
                                                    <th>Last 7 Days Active Users</th>                                         
                                                    <th>Last 30 Days Active Users</th>
                                                    <th>All Questions Attempted</th>
                                                    <th>Questions Attempted Today</th>
                                                    <th>Questions Attempted Yesterday</th>
                                                    <th>Questions Attempted Last 7 Days</th>
                                                    <th>Questions Attempted Last 30 Days</th>
                                                    
                                                    
                                                  </tr>  
                                                </thead>
                                               
                                               
                                              {this.state.historical_admin_dashboard_data.map((item, index) => ( 
                                                       
                                              <tbody>
                                                <tr>
                                               
                                                    <td>{index+1}</td>
                                                    <td>{item.last_updated_at}</td>
                                                    <td>{item.data_value.registration.all_users}</td>
                                                    <td>{ this.get_time_on_app_today_value(index)}</td>                               
                                                    <td>{item.data_value.registration.registered_today}</td>
                                                    <td>{item.data_value.registration.registered_yesterday}</td>
                                                    <td> {item.data_value.registration.registered_last_7days}</td>
                                                    <td> {item.data_value.registration.registered_last_30days}</td>                       
                                                    <td>{item.data_value.last_login.last_login_today}</td>
                                                    <td>{item.data_value.last_login.last_login_yesterday}</td>
                                                  
                                                    <td>{item.data_value.last_login.last_login_last_7days}</td>
                                                    <td>{item.data_value.last_login.last_login_last_30days}</td>
                                                  
                                                    <td> {item.data_value.question_attempts.all_attempts}</td>
                                                    <td>{item.data_value.question_attempts.attempted_today}</td>
                                                    <td>{item.data_value.question_attempts.attempted_yesterday}</td>
                                                    <td>{item.data_value.question_attempts.attempted_last_7days}</td>

                                                    <td>{item.data_value.question_attempts.attempted_last_30days}</td>
                                                   
                                                   
                                                </tr>  
                                              </tbody>   
                                            
                                        ))}  
                                        </table>

                                        </div>



                  return page
     
  }

  render() {
   
    let page = this.get_historical_admin_data_page()
  
    return (

     
     
      <div >
                 <h1  style={{marginLeft:"30%",}}>Dashboard</h1>
                <button style={{marginLeft:"30%", marginTop:"1%"}}  onClick={this.get_historical_admin_platform_data} >Refresh Data</button>
                {page}











            
      </div>
   
    );
  }

}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {

                    button_disable:false,
                    form_data:{
                          username:'',
                          password:''
                     }     

                };
  }

  handleChange=(event)=> {
   
    let new_form_data= this.state.form_data;
    
            let key;
            for (key in new_form_data){

                  if(key===event.target.id){
                        new_form_data[key]=event.target.value;
                        this.setState({form_data: new_form_data});
                        
                  } 
            }


  }


  

  handle_sign_in=(event)=> {
    
    this.setState({ button_disable: true })        
    let url = base_url;
        url= url + 'get-login-token'
   
    let token = localStorage.getItem("login_token")    
     
    let token_header= { Authorization :  'Token '+token} 

    console.log(this.state.form_data)
    axios({
      method: 'post',
      url: url,
      data:this.state.form_data,
      headers:token_header
    })
    
      .then(res => {

           
            this.setState({ button_disable: false })

           
           
          
            localStorage.login_token= res.data.token
           
      })

      .catch((error)=> {
          // handle error
          console.log(error);
          this.setState({ button_disable: false }) 
          toast("Wrong username/password combination")
         
      })

  }



  render() {
      return (
        <div className='center_div'>
          <form>
         
              <h3>Sign In</h3>

              <div className="form-group">
                  <label>Username</label>
                  <input id="username" type="username" className="form-control" placeholder="Enter username" value={this.state.form_data.username} onChange={this.handleChange}/>
              </div>

              <div className="form-group">
                  <label>Password</label>
                  <input id="password" type="password" className="form-control" placeholder="Enter password" value={this.state.form_data.password} onChange={this.handleChange} />
              </div>

              <div className="form-group">
                  <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                  </div>
              </div>

              <button type="button" className="btn btn-primary btn-block" onClick={this.handle_sign_in} disabled={this.state.button_disable}>Sign in</button>
              <p className="forgot-password text-right">
                  Forgot <a href="#">password?</a>
              </p>
          </form>
          </div>
      );
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class SignUp extends Component {

  render() {
      return (

        <div className='center_div'>
          
          <form>
              <h3>Sign Up</h3>

              <div className="form-group">
                  <label>First name</label>
                  <input type="text" className="form-control" placeholder="First name" />
              </div>

              <div className="form-group">
                  <label>Last name</label>
                  <input type="text" className="form-control" placeholder="Last name" />
              </div>

              <div className="form-group">
                  <label>Email address</label>
                  <input type="email" className="form-control" placeholder="Enter email" />
              </div>

              <div className="form-group">
                  <label>Password</label>
                  <input type="password" className="form-control" placeholder="Enter password" />
              </div>

              <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
              <p className="forgot-password text-right">
                  Already registered <a href="#">sign in?</a>
              </p>
          </form>
          </div>
      );
  }
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class HeaderSection extends Component {

 
  componentDidMount() {

    //the codes below is used to import an external js 'animenu' script responsible for the menu button toggle
    var script = document.createElement('script')
    script.src = "js/animenu.js"
    script.type="text/babel"
    script.class = "external-script"
    document.body.appendChild(script);
    //the codes above is used to import an external js script responsible for the menu button toggle
  

 } 

 logout=()=>{

  localStorage.removeItem("login_token");
  this.props.save_user_account_to_store(null)

 }

 get_admin_menu=()=>{

      let admin_menu =  <li  >
                      <a href="#">Admin</a>

                            <ul className="animenu__nav__dropdown" aria-label="submenu" role="menu">

                                  <li>
                                  <Link to="admin-dashboard">User Info</Link>
                                  </li>
                                  <li>
                                  <Link to="/historical-admin-dashboard">Historical User Info</Link>
                                  </li>
                            </ul>
                      </li>


        return admin_menu

 }

  render() {
   

    let admin_menu = null


   
            
              if(this.props.user_account.platform === "admin"){ admin_menu = this.get_admin_menu()}


    

    return (
      
      <div  >

          <div >
            <nav className="animenu" role="navigation" aria-label="Menu" >
                <button className="animenu__btn">
                <span className="animenu__btn__bar"></span>
                <span className="animenu__btn__bar"></span>
                <span className="animenu__btn__bar"></span>
                </button>
      
                <ul className="animenu__nav">
                       <li  >
                            <Link to="/"  >Home</Link>
                       </li>
                  
                      <li>
                          <a href="#">Questions</a>
                              <ul className="animenu__nav__dropdown" aria-label="submenu" role="menu">

                                    <li  >
                                        <Link to="/view-all-question">View All Questions </Link>
                                    </li>
                                    <li  >
                                          <Link to="/add-new-question">Create New Questions</Link>
                                    </li>

                                   
                              </ul>
                      </li> 

                      <li  >
                                  <Link to="view-all-addendum">Addendums</Link>
                      </li>
                      
                      <li  >
                                  <Link to="view-all-formula">Formula Sheets</Link>
                      </li>
					            <li  >
                                  <Link to="/view-all-solution">Solutions</Link>
                      </li>



                      <li  >
                                 <a href="#">News</a>

                                 <ul className="animenu__nav__dropdown" aria-label="submenu" role="menu">

                                        <li>
                                        <Link to="/add-new-news">Create a News</Link>
                                        </li>
                                        <li>
                                        <Link to="/view-all-news">View All News</Link>
                                        </li>
                                  </ul>
                      </li>

                     
                      {admin_menu}


                      <li> 
                      <button type="button" onClick={this.logout}>Logout</button>
                      </li>
                      
                </ul>
            </nav>
          </div>  



      </div>
   
    );
  }

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Dashboard extends Component {
  

  constructor(props) {
    super(props);
    this.state = { 
                      form_data:''



    }
  }
  
  handleChange=(event)=> {
   
    
                  //the event.editor check below is to stop the script from throwing error incase its not a ckeditor event
                  if(event.editor!==undefined){

                            // event.editor.config.bodyId below is the only way to identify the compnent id of a ck editor component so as to be
                            //able to set its data to state using event.editor.getData() method of the event object produce d by ck editor
                  
                       
                                  event.editor.getData();
                                  this.setState({form_data:event.editor.getData()});

                            
                  }





         


  }
  render() {
    //this is the code for dropdown menu
    //<li>
  //  <a href="#">Categories</a>
    //<ul className="animenu__nav__dropdown" aria-label="submenu" role="menu">
     //     <li  style={{animation:"jingle 10s infinite"}}>
        //      <Link to="/english_to_french">English to French</Link>
        //  </li>
         // <li  style={{animation:"jingle 10s infinite"}}>
         //     <Link to="/french_to_english">French to English</Link>
        //  </li>
   // </ul>
  //</li>
  
    return (

     
     
      <div >

                            <CKEditor data={this.state.form_data} onChange={this.handleChange} 
                                            config={ {
                                                      //toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:50,
                                                      bodyId:"correct_answer"                           
                                            } }
                                      />
                                         <div dangerouslySetInnerHTML={{ __html: this.state.form_data }} />
                                              {this.state.form_data}















            <h2>Admin app Version 1.8.1</h2>
            <h2>Backend app Version 1.5.0</h2>
            <h2>Learner App Version 1.0.1</h2>
            <hr></hr>


            <h4>01 September 2020</h4>
                <p>Admin app Version 1.8.1</p>
               

               <p>1. Restore Addendum, Solution and Formula image width to 1000px on Question upload page and all Model edit pages  </p>

              

              <hr></hr>

            <h4>27 August 2020</h4>
                <p>Admin app Version 1.8.0</p>
               <p> Backend app Version 1.5.0</p>

               <p>1. Performance fix : Re-wrote the shitty code that is stressing the server(by eliminating for-loop in the api view and
                 replacing it with a serializer that omits the image field). View Solution page that takes about 11 seconds to get 300 items from db 
                 now does it in 88 miliseconds. codes for View Addendum Page and View Formula Page were also rewritten to fit this format as well  </p>

              <p>2. Added rich text functionalities(underline, superscript, subscript, special characters and strike through to neccessary fields
                  on create question page and edit question page </p>
              <p>3. Integrate rich text view on question detail page using dangerouslySetInnerHTML, so properly formatted content can be viewed on the page</p>
              <p>4.  Created filter system for View all Addendum, Solution, and Formula pages.</p>

              <hr></hr>



            <h4>20 August 2020</h4>
                <p>Admin app Version 1.7.0</p>
               <p> Backend app Version 1.4.1</p>

               <p>1. Bug fix: Questions not deleting after last update</p>

              <p>2.Bug fix: Page going back to first page after exiting an item detail page on addendum,solution and formula pages</p>
              <p>3.Added Functinality: addendum, solutions and formula can now be approved on question detail modal page</p>
              <p>4.Move question origin to the top on question detail modal page as requested by admin @sholaakins</p>

              <hr></hr>


            <h4>17 August 2020</h4>
                <p>Admin app Version 1.6.0</p>
               <p> Backend app Version 1.4.0</p>

               <p>1. Create approval system for formula, addendum and solution</p>

              <p>2.Create edit system for Formula, Addendum and Solution</p>
              <p>3.Put Image width 500px restriction on Formula, Addendum and Solution uploads and edits</p>
              <p>4.Display Addendum status, Formular Status and Solution status on view question detail modal page</p>

              <hr></hr>

            <h4>15 August 2020</h4>
                <p>Admin app Version 1.5.0</p>

               <p>1. Added Functionality: Show addendum image , solution image and formula image on Question detail modal page on view all Questions page</p>
         
              <hr></hr>

            <h4>13 August 2020</h4>
                <p>Admin app Version 1.4.0</p>
               <p> Backend app Version 1.3.1</p>

               <p>1. fix bug :question origin value not showing on edit modal page</p>

              <p>2. fix bug : question origin not changing after it was edited and saved</p>
              <p>3.added functionality: List of questions automatically changes without needing to reload the page and updates after editing, approving or deleting a question</p>
              <hr></hr>
            <h4>12 August 2020</h4>
                <p>Admin app Version 1.3.0</p>
               <p> Backend app Version 1.3.0</p>

               <p>1. Added Question origin to both admin and backend</p>

              <p>2. added edit, delete and approve to question detail modal page</p>
              <p>3. Re-arrange question list page asper admin @sholaakins request</p>
              <hr></hr>
            <h4>10 August 2020</h4>
                <p>Admin app Version 1.2.0</p>
               <p> Backend app Version 1.2.0</p>

                <p>1. Added multi permission user system and approval to admin platform and backend to multi user question upload and approval</p>

                <p>2. Added multi filtering system to admin platform and backend, for easy resource retrievals for admin</p>
                <p>3. Added support for pagination to question, addendum, solution and formula pages on admin platform and backend</p>

               
            
      </div>
   
    );
  }

}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



class Add_new_question extends Component {
  constructor(props) {
    super(props);
    this.state = {  
                    open_addendum_modal_page:false,
                    open_solution_modal_page:false,
                    open_formula_modal_page:false,
                    button_disable:false,
                    is_edit:false,
                    has_set_edit_values:false,
                          form_data: {
                                          id:null,
                                          question_type:'',
                                          question:'',
                                          correct_answer:'',
                                          option1:'',
                                          option2:'',
                                          option3:'',
                                          year:'',
                                          subject:'',
                                          topic:'',
                                          month:'',
                                          question_origin:'',
                                          addendum_id:'',
                                          solution_id:'',
                                          formula_id:'',
                                       },

                    addendum_data :   {
                                        image:'',
                                        image_width:'',
                                        image_height:'',
                                        image_size:'',

                                       },  

                    solution_data :   {
                                        image:'',
                                        image_width:'',
                                        image_height:'',
                                        image_size:'',

                                       } ,

                    formula_data :   {
                                        image:'',
                                        image_width:'',
                                        image_height:'',
                                        image_size:'',

                                       }                    
                };
         
  }

  componentDidMount(){
        //this code below activates the edit functionality of this component 
        if(typeof this.props.edit !== "undefined" ){
              this.setState({ is_edit: true })
         
        } 
    //this code above activates the edit functionality of this component 


  }

  //this two functions below is for the modal replay box
  handle_open_modal=(event)=> {
        if(event.target.id==="open_addendum_modal"){
             this.setState({ open_addendum_modal_page: true })
        }
        if(event.target.id==="open_solution_modal"){
             this.setState({ open_solution_modal_page: true })
        }
        if(event.target.id==="open_formula_modal"){
          this.setState({ open_formula_modal_page: true })
       }
        

        
  }

  handle_close_modal=(event)=> {
  
        if(event.target.id==="close_addendum_modal"){
              this.setState({ open_addendum_modal_page: false })
        }
        if(event.target.id==="close_solution_modal"){
            this.setState({ open_solution_modal_page: false })
        }
        if(event.target.id==="close_formula_modal"){
          this.setState({ open_formula_modal_page: false })
         }
  }

//this two functions above is for the modal replay box  

  getBase64=(event)=> {

        var files = document.getElementById(event.target.id).files; 
        if(files[0].type=='image/jpeg' && files.length > 0){

                
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                if (event.target.id==='image_question'){
                          reader.onload =()=> {   
                                this.setState(state => (state.form_data.question = reader.result, state))
                          }
                 
                      //this script below verifies the image dimension and should be placed inside reader.onload function
                      //Initiate the JavaScript Image object.
                      var image = new Image();

                      //Set the Base64 string return from FileReader as source.
                      image.src = reader.result;
                      
                      image.onload = function () {
                            var height = this.height;
                            var width = this.width;
                            if ( width < 1000) {
                                alert("Width must be upto 1000px");
                                return false;
                            }
                            alert("Uploaded image has valid Height and Width.");
                            return true;
                        }
                    //this script above verifies the image dimension
                       
                  };
                if (event.target.id==='image_correct_answer'){
                          reader.onload = ()=>{   
                              this.setState(state => (state.form_data.correct_answer = reader.result, state))
                          }     

                } 
              
                if (event.target.id==='image_option1'){
                        reader.onload =()=>{   
                          this.setState(state => (state.form_data.option1 = reader.result, state))
                        }     
                }


                if (event.target.id==='image_option2'){
                          reader.onload = ()=>{   
                            this.setState(state => (state.form_data.option2 = reader.result, state))
                          }     
                 }

                if (event.target.id==='image_option3'){
                        reader.onload = ()=> {   
                          this.setState(state => (state.form_data.option3 = reader.result, state))
                        }     
                }

                if (event.target.id==='upload_new_addendum'){
                            reader.onload = ()=> {   
                                      this.setState(state => (state.addendum_data.image = reader.result, state))
                                      //this script below verifies the image dimension and should be placed inside reader.onload function
                                      //Initiate the JavaScript Image object.
                                      var image = new Image();
                                      //Set the Base64 string return from FileReader as source.
                                      image.src = reader.result;
                                      // this code below calculates the file size in kilobytes
                                      let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                                      let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                                      //this code above calculates the file size in kilobytes
                                      this.setState(state => (state.addendum_data.image_size = image_size, state))
                                      //this code below calculates the image size and width
                                      image.onload = ()=> {
                                            var image_height = image.height;
                                            var image_width = image.width;
                                            if (image_width==1000){
                                              this.setState(state => (state.addendum_data.image_width = image_width, state)) 
                                              this.setState(state => (state.addendum_data.image_height = image_height, state))
                                            }
                                            else{
                                                this.setState(state => (state.addendum_data.image_size = '', state))
                                                this.setState(state => (state.addendum_data.image = '', state))
                                                alert('Image width Must be 1000 pixels')
                                            }
                                           
                                            
                                      }
                                      
                            }
                  
                   }
                

                if (event.target.id==='upload_new_solution'){
                          reader.onload = ()=> {   
                                this.setState(state => (state.solution_data.image = reader.result, state))
                                //this script below verifies the image dimension and should be placed inside reader.onload function
                                //Initiate the JavaScript Image object.
                                var image = new Image();
                                //Set the Base64 string return from FileReader as source.
                                image.src = reader.result;
                                // this code below calculates the file size in kilobytes
                                let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                                let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                                //this code above calculates the file size in kilobytes
                                this.setState(state => (state.solution_data.image_size = image_size, state))
                                //this code below calculates the image size and width
                                image.onload = ()=> {
                                          var image_height = image.height;
                                          var image_width = image.width;
                                          if (image_width==1000){
                                            this.setState(state => (state.solution_data.image_width = image_width, state)) 
                                            this.setState(state => (state.solution_data.image_height = image_height, state))
                                          }
                                          else{
                                              this.setState(state => (state.solution_data.image_size = '', state))
                                              this.setState(state => (state.solution_data.image = '', state))
                                              alert('Image width Must be 1000 pixels')
                                          }
                                          
                                  }
                            
                          }      
                    }

                if (event.target.id==='upload_new_formula'){
                      reader.onload = ()=> {   
                            this.setState(state => (state.formula_data.image = reader.result, state))
                            //this script below verifies the image dimension and should be placed inside reader.onload function
                            //Initiate the JavaScript Image object.
                            var image = new Image();
                            //Set the Base64 string return from FileReader as source.
                            image.src = reader.result;
                            // this code below calculates the file size in kilobytes
                            let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                            let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                            //this code above calculates the file size in kilobytes
                            this.setState(state => (state.formula_data.image_size = image_size, state))
                            //this code below calculates the image size and width
                            image.onload = ()=> {
                                      var image_height = image.height;
                                      var image_width = image.width;
                                      if (image_width==1000){
                                        this.setState(state => (state.formula_data.image_width = image_width, state)) 
                                        this.setState(state => (state.formula_data.image_height = image_height, state))
                                      }
                                      else{
                                          this.setState(state => (state.formula_data.image_size = '', state))
                                          this.setState(state => (state.formula_data.image = '', state))
                                          alert('Image width Must be 1000 pixels')
                                      }
                              }
                        
                      }      
                }
                    


                reader.onerror = function (error) {
                  alert('Error: ', error);
                };
                
        }
          
        else {
          alert('Wrong file type or no file selected')
        }         
  }


  


  handle_question_submit=(event)=> {
              this.setState({ button_disable: true })        
              let url = base_url;
                  url= url + 'add-new-question'
              if(this.state.is_edit===true){url= base_url+'edit-question'}

              let token = localStorage.getItem("login_token")       
              let token_header= { Authorization :  'Token '+token} 

              console.log(this.state.form_data)
              axios({
                method: 'post',
                url: url,
                data:this.state.form_data,
                headers:token_header
              })
              
                .then(res => {

                      if(this.state.is_edit===false){
                              this.setState(state => (state.form_data.question_type = '', state))   
                              this.setState(state => (state.form_data.question = '', state))   
                              this.setState(state => (state.form_data.correct_answer = '', state))
                              this.setState(state => (state.form_data.option1 = '', state))
                              this.setState(state => (state.form_data.option2 = '', state))
                              this.setState(state => (state.form_data.option3 = '', state))
                              this.setState(state => (state.form_data.addendum_id = '', state))
                              this.setState(state => (state.form_data.solution_id = '', state))
                              this.setState(state => (state.form_data.topic = '', state))
                              this.setState(state => (state.form_data.question_origin = '', state))
                                              
                       }
                       this.setState({ button_disable: false })
                       toast("Success") 
                })

                .catch((error)=> {
                    // handle error
                    console.log(error);
                    this.setState({ button_disable: false }) 
                    toast("Failure")
                   
                })
    
  }



  handle_addendum_or_solution_or_formula_upload=(event)=> {
                      
   
    let url = base_url;
    let upload_data = {};
   
    if(event.target.id==='upload_addendum'){
           url= url + 'add-new-addendum';

           if(this.state.addendum_data.image!==""){
                  upload_data=this.state.addendum_data 
           }
           else{alert('No image selected, Please select an image'); return}
                 
    } 
    
    if(event.target.id==='upload_solution'){
            url= url + 'add-new-solution';
            if(this.state.solution_data.image!==""){
                    upload_data=this.state.solution_data 
            }
            else{alert('No image selected, Please select an image'); return}
    } 
    
    if(event.target.id==='upload_formula'){
            url= url + 'add-new-formula';
            if(this.state.formula_data.image!==""){
                    upload_data=this.state.formula_data 
            }
            else{alert('No image selected, Please select an image'); return}
    }  

    if(upload_data.image !== ''){ 
      this.setState({ button_disable: true })
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 

          console.log(this.state.formula_data)
            axios({
              method: 'post',
              url: url,
              data:upload_data,
              headers:token_header
            })
            
              .then(res => {
                      console.log (res.data)
                      let empty_object= {
                                          image:'',
                                          image_width:'',
                                          image_height:'',
                                          image_size:'',

                                         } 
                      if(res.data[0]==='addendum'){
                       
                        this.setState(state => (state.form_data.addendum_id = res.data[1].id, state)) 
                        this.setState({ open_addendum_modal_page: false })
                        this.setState({ addendum_data: empty_object })
                      } 
                      if(res.data[0]==='solution'){
                           
                        this.setState(state => (state.form_data.solution_id = res.data[1].id, state))   
                        this.setState({ open_solution_modal_page: false })
                        this.setState({ solution_data: empty_object })
                      }
                      if(res.data[0]==='formula'){
                           
                        this.setState(state => (state.form_data.formula_id = res.data[1].id, state))   
                        this.setState({ open_formula_modal_page: false })
                        this.setState({ formula_data: empty_object })
                      } 

                      this.setState({ button_disable: false }) 
               
                toast("Success")
                
              })

              .catch((error)=> {
                  // handle error
                  console.log(error);
                  this.setState({ button_disable: false }) 
                  toast("Failure")
                
              })
    }

  }




  handleChange=(event)=> {
   
        let new_form_data= this.state.form_data;
        
                let key;
                for (key in new_form_data){

                  // the event.target check below is needed to avoid throwing errors incase the event is a ck editor event
                  if(event.target!==undefined){

                                  if(key===event.target.id){

                                        new_form_data[key]=event.target.value;
                                        this.setState({form_data: new_form_data});     

                                        if(key==='question_type'){
                                              this.setState(state => (state.form_data.question = '', state))   
                                              this.setState(state => (state.form_data.correct_answer = '', state))
                                              this.setState(state => (state.form_data.option1 = '', state))
                                              this.setState(state => (state.form_data.option2 = '', state))
                                              this.setState(state => (state.form_data.option3 = '', state))
                                        }
                                  } 

                    }    
                      //the event.editor check below is to stop the script from throwing error incase its not a ckeditor event
                      if(event.editor!==undefined){

                                // event.editor.config.bodyId below is the only way to identify the compnent id of a ck editor component so as to be
                                //able to set its data to state using event.editor.getData() method of the event object produce d by ck editor
                      
                                if(key===event.editor.config.bodyId){

                                      new_form_data[key]=event.editor.getData();
                                      this.setState({form_data: new_form_data});

                                }
                      }





                }


  }

  set_edit_values=()=>{

          let new_form_data= this.state.form_data
          new_form_data.id = this.props.edit.id
          new_form_data.year = this.props.edit.year 
          new_form_data.month = this.props.edit.month
          new_form_data.subject = this.props.edit.subject 
          new_form_data.topic = this.props.edit.topic
          new_form_data.question_type = this.props.edit.question_type 
          new_form_data.question_origin = this.props.edit.question_origin
          new_form_data.question = this.props.edit.question 
          new_form_data.correct_answer = this.props.edit.correct_answer
          
          new_form_data.option1 = this.props.edit.option1
          new_form_data.option2 = this.props.edit.option2
          new_form_data.option3 = this.props.edit.option3
          new_form_data.addendum_id = this.props.edit.addendum_id
          new_form_data.solution_id = this.props.edit.solution_id
          new_form_data.formula_id = this.props.edit.formula_id
          this.setState({ form_data: new_form_data })
          this.setState({ has_set_edit_values: true })


  }
  render() {
      // this code below is for initiating edit values in the form field when this component is used for editting
      if(this.state.is_edit===true){

              if(this.state.has_set_edit_values===false){
                      if(this.props.edit!==null){                    
                        this.set_edit_values()
                      }
                }
      }
    // this code above is for initiating edit values in the form field when this component is used for editting

      let page_heading=<h3>Create New Question</h3>
      if(this.state.is_edit===true){ page_heading=<h3>Edit Question</h3>}

      let topics=[];

      if (this.state.form_data.subject=='mathematics'){

          topics =  mathematics_topics
      }                  


      if (this.state.form_data.subject=='mathematical_literacy'){

          topics= mathematical_literacy_topics
      }           



      if (this.state.form_data.subject=='life_sciences'){

          topics = life_sciences_topics
      }
  
  
      if (this.state.form_data.subject=='physical_sciences'){

          topics= physical_sciences_topics             
      }        



      if (this.state.form_data.subject=='history'){

          topics= history_topics       
      }     



      if (this.state.form_data.subject=='business_studies'){

          topics= business_studies_topics
      }         

      if (this.state.form_data.subject=='accounting'){

          topics = accounting_topics
      }   



      if (this.state.form_data.subject=='economics'){

          topics= economics_topics
      }   



      if (this.state.form_data.subject=='agricultural_science'){

          topics=  agricultural_science_topics  
      }
     


      let objective_pannel=null;

      if (this.state.form_data.question_type=='objective_question'){

          objective_pannel= <div>
                                    <hr></hr>
                                    <label for="question"> Type The Question below :</label><br/>
                                    <CKEditor data={this.state.form_data.question} onChange={this.handleChange} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:90,
                                                      bodyId:"question"                           
                                            } }
                                    />

                                      <hr></hr>
                                      <label for="correct_answer">Type the Correct Answer below:</label><br/>
                                      <CKEditor data={this.state.form_data.correct_answer} onChange={this.handleChange} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:50,
                                                      bodyId:"correct_answer"                           
                                            } }
                                      />
                                      
                                
                                      <hr></hr>
                                      <label for="option1">Type Option 1 below:</label><br/>
                                      <CKEditor data={this.state.form_data.option1} onChange={this.handleChange} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:50,
                                                      bodyId:"option1"                           
                                            } }
                                      />
                                      
                                    
                                      <hr></hr>
                                      <label for="option2">Type Option 2 below:</label><br/>
                                      <CKEditor data={this.state.form_data.option2} onChange={this.handleChange} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:50,
                                                      bodyId:"option2"                           
                                            } }
                                      />
                                        
                                        <hr></hr>
                                      <label for="option3">Type Option 3 below :</label><br/>
                                      <CKEditor data={this.state.form_data.option3} onChange={this.handleChange} 
                                            config={ {
                                                      toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                      height:50,
                                                      bodyId:"option3"                           
                                            } }
                                      />
                                      <hr></hr>
                     
                            </div>

      }
      let fill_in_the_gap_pannel=null;
      if (this.state.form_data.question_type=="fill_in_the_gap"){
              fill_in_the_gap_pannel= <div>
                                              <hr></hr>
                                              <label for="question"> Type The Question below :</label><br/>
                                              <CKEditor data={this.state.form_data.question} onChange={this.handleChange} 
                                                      config={ {
                                                                toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                                height:90,
                                                                bodyId:"question"                           
                                                      } }
                                              />

                                                <hr></hr>
                                                <label for="correct_answer">Type the Correct Answer below:</label><br/>
                                                <CKEditor data={this.state.form_data.correct_answer} onChange={this.handleChange} 
                                                      config={ {
                                                                toolbar: [ [ 'Underline', 'Strike', 'Subscript', 'Superscript', '-','SpecialChar',] ],
                                                                height:50,
                                                                bodyId:"correct_answer"                           
                                                      } }
                                                />

                                        </div>

      }   


      let image_objective_pannel = null
      if (this.state.form_data.question_type=="image_objective"){

        image_objective_pannel =<div>
                               <label for="image_question">Question :</label>
                        <input id="image_question" className='questionform' onChange={this.getBase64} type="file" accept="image/*"/>

                        <br/>
                        <label for="image_correct_answer">Correct Answer:</label>
                        <input id="image_correct_answer" className='questionform' onChange={this.getBase64} type="file" accept="image/*"/>

                        <br/>
                        <label for="image_option1">Option 1:</label>
                        <input id="image_option1" className='questionform' onChange={this.getBase64} type="file" accept="image/*"/> 

                        <br/>
                        <label for="image_option2">Option 2:</label>
                        <input id="image_option2" className='questionform' onChange={this.getBase64} type="file" accept="image/*"/>

                        <br/>
                        <label for="image_option3">Option3:</label>
                        <input id="image_option3" className='questionform' onChange={this.getBase64} type="file" accept="image/*"/>



        </div>



      }
     
    return (
      
      <div  >

            {page_heading}
            <form className='question_form_div'>
                      
                 
                      <label htmlFor="year">Year: </label>                      
                      <select id="year" className='questionform' value={this.state.form_data.year} onChange={this.handleChange} >
                            <option value="">Select  Exam Year</option>
                            {years.map(item => ( 
                            <option value={item}>{item}</option>
                            ))}  
                      </select>
                    <br/>

                    <label htmlFor="month">Month:</label>
                    <select id="month" className='questionform' value={this.state.form_data.month} onChange={this.handleChange} >
                          <option value="">Select  Exam Month</option>
                          <option value="may">Amended Senior Certificate - May</option>
                          <option value="november">National Senior Certificate - November</option>
                    </select>

                    <br/>
                    <label htmlFor="subject">Subject:</label>
                    <select id="subject" className='questionform' value={this.state.form_data.subject} onChange={this.handleChange} >
                          <option value="">Select a Subject</option>
                            {subjects.map(item => ( 
                            <option value={item.subject_code}>{item.subject_description}</option>
                            ))}
                    </select>

                    <br/>
                              
                    <label htmlFor="topic">Topic:</label>
                    <select id="topic" className='questionform' value={this.state.form_data.topic} onChange={this.handleChange}>
                            <option value="">Select a Topic</option>
                            {topics.map(item => ( 
                            <option value={item.topic_code}>{item.topic_description}</option>
                            ))}      
                    </select>

                    <br/>    
                      
                        <label htmlFor="question_origin">Question Origin:</label>
                        <input id="question_origin" className='questionform'  value={this.state.form_data.question_origin} onChange={this.handleChange} />
                       

                    <br/>    
                      
                        <label htmlFor="addendum_id">Addendum Id:</label>
                        <input id="addendum_id" className='questionform' type="number" value={this.state.form_data.addendum_id} onChange={this.handleChange} />
                        <button type="button" id="open_addendum_modal" onClick={this.handle_open_modal}>Upload New Addendum</button>
                       
                    <br/>    
                      
                      <label htmlFor="solution_id">Solution Id:</label>
                      <input id="solution_id" className='questionform' type="number" value={this.state.form_data.solution_id} onChange={this.handleChange} />
                      <button type="button" id="open_solution_modal" onClick={this.handle_open_modal}> Upload New Solution</button>
                               
                      <br/>

                      <br/>    
                      
                      <label htmlFor="formula_id">Formula Sheet Id:</label>
                      <input id="formula_id" className='questionform' type="number" value={this.state.form_data.formula_id} onChange={this.handleChange} />
                      <button type="button" id="open_formula_modal" onClick={this.handle_open_modal}> Upload New Formula Sheet</button>
                               
                      <br/>

                      <fieldset>
                      
                      <label htmlFor="month">Question Type:</label>
                      <select id="question_type" className='questionform' value={this.state.form_data.question_type} onChange={this.handleChange} >
                              <option value="">Select Question Type</option>
                              <option value="objective_question">Objective Questions</option>
                              <option value="fill_in_the_gap">Fill in the Gap</option>
                              <option value="image_objective">Image Objectives</option>
                     </select>
                    
                      
                     <br/>  

                     

                        {objective_pannel}   
                        {fill_in_the_gap_pannel} 
                        {image_objective_pannel}

                  </fieldset> 

                                 

                        <br/>
                        <button type="button" className='question_form_submit_button' onClick={this.handle_question_submit} disabled={this.state.button_disable} >Save Question</button>
                    
                        
            </form>
            <Modal isOpen={this.state.open_addendum_modal_page} contentLabel="Minimal Modal Example">
        
                            <button id="close_addendum_modal" type="button" onClick={this.handle_close_modal}>Exit</button>
                            <h3>Upload New Addendum</h3>
                            <input id="upload_new_addendum" onChange={this.getBase64} type="file" accept="image/*"/>
                            
                            
                            
                            <button id='upload_addendum' onClick={this.handle_addendum_or_solution_or_formula_upload} disabled={this.state.button_disable} >Upload Addendum and get ID</button>
                              
            </Modal>                    
            <Modal isOpen={this.state.open_solution_modal_page} contentLabel="Minimal Modal Example">
        
                            <button id="close_solution_modal" type="button" onClick={this.handle_close_modal}>Exit</button>
                            <h3>Upload New Solution</h3>
                            <input id="upload_new_solution" onChange={this.getBase64} type="file" accept="image/*"/>
                           
                         
                            
                            <button id='upload_solution' onClick={this.handle_addendum_or_solution_or_formula_upload} disabled={this.state.button_disable}>Upload Solution and get ID </button>
                              
            </Modal>  
            <Modal isOpen={this.state.open_formula_modal_page} contentLabel="Minimal Modal Example">
        
                            <button id="close_formula_modal" type="button" onClick={this.handle_close_modal}>Exit</button>
                            <h3>Upload New Formula Sheet</h3>
                            <input id="upload_new_formula" onChange={this.getBase64} type="file" accept="image/*"/>
                           
                         
                            
                            <button id='upload_formula' onClick={this.handle_addendum_or_solution_or_formula_upload} disabled={this.state.button_disable}>Upload Formula Sheet and get ID </button>
                              
            </Modal>



      </div>
   
    );
  }

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class View_all_questions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  list_of_question: [],
                  open_question_detail_modal_page:false,
                  open_question_edit_modal_page:false,
                  current_viewed_question_in_modal:null,                 
                  total_questions_available:0,
                  next_page:'',
                  current_page:'',
                  previous_page:'',

                  current_addendum_image:'',
                  current_solution_image:'',
                  current_formula_image:'',

                  filter_data: {
                                  id:'',
                                  year:'',
                                  subject:'',
                                  month:'', 
                                  created_by:'',
                                  approved_by:'',
                                  approved:''           
                                },

                               
                };
  }

  componentDidMount(){
    this.get_info_of_questions_from_db()



  }

  get_info_of_questions_from_db=(control_url)=>{


          if (control_url===null){return}
          let url= control_url
         
          if(control_url == undefined){ url = base_url + 'list-of-all-question'+'?page=1'}
          
          this.setState({current_page:url})
          let token = localStorage.getItem('login_token')       
          let token_header= { Authorization :  'Token '+token} 
          let upload_data = this.state.filter_data
          
          axios({
            method: 'post',
            url: url,
            data:upload_data,
            headers:token_header
          })
          
            .then(res => {
             console.log (res.data)
                  this.setState({list_of_question:res.data.results,
                                total_questions_available: res.data.count,
                                next_page:res.data.next,
                                previous_page:res.data.previous,
                             });

                    toast("Success")
             
              
            })

            .catch((error)=> {
                // handle error
                toast("Failed")
                console.log(error);       
              
            })

  }

  load_individual_question_detail=(id)=>{
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
     
      let request_data=['question', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
          this.setState({current_viewed_question_in_modal:res.data});
          this.populate_current_viewed_question_in_modal_addendum_and_solution_and_formula()
          toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  delete_individual_question_detail=(id)=>{

    if (window.confirm("DELETE this question?")===false) {return}
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['question', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 

      axios({
        method: 'delete',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
         
              let x=0
              let old_list = this.state.list_of_question;
                    
                    while (x < old_list.length){
        
                          if (old_list[x].id ==id ){  
                              
                                old_list.splice(x, 1)
                          
                          }
                          x=x+1;
                    }
                    
              this.setState({list_of_question:old_list}); 
              this.handle_close_modal()

              toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  approve_question=(id)=>{
    if (window.confirm("APPROVE this question")===false) {return}

    let url = base_url ;
    url = url + 'approve-question'            
      
      let request_data={id}
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 

      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
                     

              this.handle_close_modal()
              toast("Success")
             
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  handle_open_modal=(event, id)=>{

      this.load_individual_question_detail(id)
      if(event.target.id=='view'){
              
              this.setState({open_question_detail_modal_page:true});
              
      }
      if(event.target.id=='edit'){
       
        this.setState({open_question_edit_modal_page:true});
       
      }

     


  }
  handle_close_modal=()=>{

    
    this.get_info_of_questions_from_db(this.state.current_page)
    this.setState({
                      open_question_detail_modal_page:false,
                      open_question_edit_modal_page:false,  
                      current_viewed_question_in_modal:null, 
      
                        current_addendum_image:'',
                        current_solution_image:'',
                        current_formula_image:'',
                      
                      
                      });
    
  }

  current_paginated_page_information_and_serial_number_key=()=>{

    let total_number_of_pages = Math.ceil(this.state.total_questions_available/pagination_amount)
    
    let current_page = this.state.current_page
   
    //this is to retrieve only numbers
     
    current_page = current_page.replace(/\D/g, "");
    //this is to remove 8000 port number while in local host
    current_page = current_page.replace(/8000/g, "");
    // this is to make default page in the the api page response is seen as page 1 in serial number
    if(current_page ===''){current_page='1'}
    let serial_number_key = (current_page-1) *pagination_amount

    let pagination_info = <div style={{width:'100%', margin:'20px 0 0px 35%'}}>
                             <p style={{float:'left'}}>Total Number of Questions = {this.state.total_questions_available }|| Page {current_page} of {total_number_of_pages} Page(s)  </p>
                             <button  style={{float:'left'}}type="button" onClick={()=>this.get_info_of_questions_from_db(this.state.previous_page)}>Previous Page</button>      
                             <button style={{float:'left'}} type="button" onClick={()=>this.get_info_of_questions_from_db(this.state.next_page)}>Next Page</button>
                           </div>
    return [pagination_info, serial_number_key]
    
  }

  handle_filter_Change=(event)=> {
   
    let new_filter_data= this.state.filter_data;
    
            let key;
            for (key in new_filter_data){

                  if(key===event.target.id){
                        new_filter_data[key]=event.target.value;
                                            
                  } 
            }
            this.setState({filter_data: new_filter_data});  

  }
  
  reset_filter=()=>{

    let new_filter_data= this.state.filter_data;
    
    let key;
        for (key in new_filter_data){

            new_filter_data[key]='';
                              
        }
        this.setState({filter_data: new_filter_data}); 
      
  }

 
  load_individual_addendum_or_solution_or_formula_detail=(model, id)=>{
          let url = base_url ;
         url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=[model, id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
              if (model==='addendum'){
                this.setState({current_addendum_image:res.data});
              }
              if (model==='solution'){
                this.setState({current_solution_image:res.data});
              }
              if (model==='formula'){
                this.setState({current_formula_image:res.data});
              }
                     
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  approve_addendum_or_solution_or_formula=(model, id)=>{
    if (window.confirm("APPROVE this Image?")===false) {return}

    let url = base_url ;
    url = url + 'approve-addendum-or-solution-or-formula'            
      
      let request_data=[model, id]
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 
      
      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
              
                 
              toast("Success")
                  
        })

        .catch((error)=> {
            console.log(error)
            toast("Failed")
               
          
        })

  }


  populate_current_viewed_question_in_modal_addendum_and_solution_and_formula=()=>{
      
      if (this.state.current_viewed_question_in_modal.addendum_id!==''){  
              this.load_individual_addendum_or_solution_or_formula_detail('addendum', this.state.current_viewed_question_in_modal.addendum_id)          
      }

      if (this.state.current_viewed_question_in_modal.solution_id!==''){
       
        this.load_individual_addendum_or_solution_or_formula_detail('solution', this.state.current_viewed_question_in_modal.solution_id)          
      }

      if (this.state.current_viewed_question_in_modal.formula_id!==''){  
        this.load_individual_addendum_or_solution_or_formula_detail('formula', this.state.current_viewed_question_in_modal.formula_id)          
      }

  }
  render() {


    let pagination_info_serial_number = this.current_paginated_page_information_and_serial_number_key()
    
    let question_detail=[];
    let image_objective_detail=null;
    if(this.state.current_viewed_question_in_modal !== null){
              if(this.state.current_viewed_question_in_modal.question_type =='objective_question'||this.state.current_viewed_question_in_modal.question_type=='fill_in_the_gap'){
                      
                
                    question_detail =    <div> 
                                              <p><b>Question Origin:</b> {this.state.current_viewed_question_in_modal.question_origin}</p>
                                              <p><b>Question Type:</b> {this.state.current_viewed_question_in_modal.question_type}</p>
                                              <p><b>Question ID:</b> {this.state.current_viewed_question_in_modal.id}</p>
                                              <p><b>Question : </b><span dangerouslySetInnerHTML={{ __html: this.state.current_viewed_question_in_modal.question}}></span></p>
                                              <p><b>Correct Answer :</b> <span dangerouslySetInnerHTML={{ __html: this.state.current_viewed_question_in_modal.correct_answer}}></span></p>
                                              <p><b>Option 1 : </b><span dangerouslySetInnerHTML={{ __html: this.state.current_viewed_question_in_modal.option1}}></span></p>
                                              <p><b>Option 2 :</b> <span dangerouslySetInnerHTML={{ __html: this.state.current_viewed_question_in_modal.option2}}></span></p>
                                              <p><b>Option 3 :</b> <span dangerouslySetInnerHTML={{ __html: this.state.current_viewed_question_in_modal.option3}}></span></p>
                    
                                              <p><b>Subject: </b>{this.state.current_viewed_question_in_modal.subject}</p>
                                              <p><b>Topic:</b> {this.state.current_viewed_question_in_modal.topic}</p>
                                              <p><b>Year:</b> {this.state.current_viewed_question_in_modal.year}</p>
                                              <p><b>Month: </b>{this.state.current_viewed_question_in_modal.month}</p>
                                              <p><b>Addendum ID:</b> {this.state.current_viewed_question_in_modal.addendum_id}</p>
                                              <p><b>Solution ID:</b> {this.state.current_viewed_question_in_modal.solution_id}</p>
                                              <p><b>Formula ID:</b> {this.state.current_viewed_question_in_modal.formula_id}</p>
                                              <p><b>Approved :</b> {this.state.current_viewed_question_in_modal.approved} </p>
                                              <p><b>Approved By:</b> {this.state.current_viewed_question_in_modal.approved_by} </p>
                                              <p><b>created By :</b> {this.state.current_viewed_question_in_modal.created_by}</p>
                                              <p><b>created at :</b> {this.state.current_viewed_question_in_modal.created_at}</p>
                                              <p><b>last Updated at :</b> {this.state.current_viewed_question_in_modal.last_updated_at}</p>
                                              
              </div>    
                    
                    
              }

              
              if(this.state.current_viewed_question_in_modal.question_type =='image_objective'){
                      image_objective_detail= <div> 
                                                    <p><b>Question Type:</b> {this.state.current_viewed_question_in_modal.question_type}</p>
                                                    <p><b>Subject: </b>{this.state.current_viewed_question_in_modal.subject}</p>
                                                    <p><b>Topic:</b> {this.state.current_viewed_question_in_modal.topic}</p>
                                                    <p><b>Year:</b> {this.state.current_viewed_question_in_modal.year}</p>
                                                    <p><b>Month: </b>{this.state.current_viewed_question_in_modal.month}</p>
                                                    <p><b>Addendum ID:</b> {this.state.current_viewed_question_in_modal.addendum_id}</p>
                                                    <p><b>Solution ID:</b> {this.state.current_viewed_question_in_modal.solution_id}</p>
                                                    <p><b>Approved :</b> {this.state.current_viewed_question_in_modal.approved} </p>
                                                    <p><b>created By :</b> {this.state.current_viewed_question_in_modal.created_by}</p>
                                                    <p><b>Question Origin :</b> {this.state.current_viewed_question_in_modal.question_origin}</p>
                                                    <p><b>Question : </b><img src={this.state.current_viewed_question_in_modal.question}  /></p>
                                                    <p><b>Correct Answer :</b> <img src={this.state.current_viewed_question_in_modal.correct_answer} alt="Red dot" /></p>
                                                    <p><b>Option 1 : </b><img src={this.state.current_viewed_question_in_modal.option1}  /></p>
                                                    <p><b>Option 2 :</b> <img src={this.state.current_viewed_question_in_modal.option2}  /></p>
                                                    <p><b>Option 3 :</b> <img src={this.state.current_viewed_question_in_modal.option3}  /></p>
                                                    
                                              </div>    
              }                                         

     

    }

    let question_origin = null
    if(this.state.current_viewed_question_in_modal!== null){
      question_origin = this.state.current_viewed_question_in_modal.question_origin
    }

    return (
              <div>
                    <h3>List of All Uploaded Questions</h3>

                   
                                        
                      <select id="year"   onChange={this.handle_filter_Change} value={this.state.filter_data.year}>
                            <option value="">All Exam Year</option>
                            {years.map(item => ( 
                            <option value={item}>{item}</option>
                            ))}  
                      </select>
                  


                   
                    <select id="month"   onChange={this.handle_filter_Change} value={this.state.filter_data.month}>
                          <option value="">All  Exam Month</option>
                          <option value="may">Amended Senior Certificate - May</option>
                          <option value="november">National Senior Certificate - November</option>
                    </select>

                    <select id="approved"   onChange={this.handle_filter_Change} value={this.state.filter_data.approved}>
                          <option value="">All Status</option>
                          <option value="true">Approved</option>
                          <option value="false">Unapproved</option>
                    </select>
                  
                   
                    <select id="subject"   onChange={this.handle_filter_Change} value={this.state.filter_data.subject}>
                          <option value="">All Subjects</option>
                            {subjects.map(item => ( 
                            <option value={item.subject_code}>{item.subject_description}</option>
                            ))}
                    </select>

                      <input id="created_by" onChange={this.handle_filter_Change} value={this.state.filter_data.created_by} placeholder="Created by"/>
                      <input id="approved_by" onChange={this.handle_filter_Change} value={this.state.filter_data.approved_by} placeholder="Approved by"/>
                      <input id="id"  type="number"  onChange={this.handle_filter_Change} value={this.state.filter_data.id} placeholder="Question ID"/>
                     
                      <button type="button" id="open_addendum_modal" onClick={this.reset_filter}>Reset Filter</button>
                      <button type="button"  onClick={()=>this.get_info_of_questions_from_db(base_url + 'list-of-all-question'+'?page=1')}>Filter Result</button>
                       








                    <br/>
                    {pagination_info_serial_number[0]}
                    <table class='table'>
                                    <thead>
                                        <tr>
                                          <th>S/N</th>
                                          <th>Question Origin</th>
                                          <th>Question ID</th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th>Approved</th>
                                          <th>Created By</th>
                                          <th>Subject</th>                                         
                                          <th>Addendum ID</th>
                                          <th>Solution ID</th>
                                          <th>Information Sheet ID</th>
                                          <th>Question Type</th>
                                          
                                          <th>Year</th>
                                          <th>Month</th>
                                          <th>Approved by</th>
                                        </tr>  
                                      </thead>
                                    {this.state.list_of_question.map((item, index) => ( 
                                    
                                    <tbody>
                                      <tr>
                                          <td>{pagination_info_serial_number[1]+index+1}</td>
                                          <td>{item.question_origin}</td>
                                          <td>{item.id}</td>
                                          <td><button  type="button" onClick={()=>{this.approve_question(item.id)}} >Approve</button></td>
                                          <td> <button  id='view' type="button" onClick={(event)=>{this.handle_open_modal(event, item.id)}}>View</button></td>
                                          <td> <button id='edit' type="button" onClick={(event)=>{this.handle_open_modal(event, item.id)}}>Edit</button></td>                       
                                          <td><button  type="button" onClick={()=>{this.delete_individual_question_detail(item.id)}} >Delete</button></td>
                                          <td>{item.approved}</td>
                                         
                                          <td>{item.created_by}</td>
                                          <td>{item.subject}</td>
                                        
                                          <td> {item.addendum_id}</td>
                                          <td>{item.solution_id}</td>
                                          <td>{item.formula_id}</td>
                                          <td>{item.question_type}</td>
                            
                                          <td>{item.year}</td>
                                          <td>{item.month}</td>
                                          <td>{item.approved_by}</td>
                                      </tr>  
                                    </tbody>   
                                  
                            ))}  
                    </table>

                   

                    {pagination_info_serial_number[0]}
                    
                   


                    <Modal  isOpen={this.state.open_question_detail_modal_page} contentLabel="Minimal Modal Example">
        
                    <button  type="button" onClick={()=>{this.approve_question(this.state.current_viewed_question_in_modal.id)}} >Approve</button>
                    <button  type="button" onClick={()=>{this.delete_individual_question_detail(this.state.current_viewed_question_in_modal.id)}} >Delete</button>    
                    <button id='edit' type="button" onClick={(event)=>{this.handle_open_modal(event, this.state.current_viewed_question_in_modal.id)}}>Edit</button>                     
                                     
                    <button  type="button" onClick={this.handle_close_modal}>Exit</button>                      
                          
                       {question_detail}         
                      {image_objective_detail}  
                      <hr></hr>
                      
                      <h3 style={{color:'red'}}>Addendum image width:{this.state.current_addendum_image.image_width} Pixels  </h3>
                      <button  type="button" onClick={()=>{this.approve_addendum_or_solution_or_formula('addendum', this.state.current_addendum_image.id)}} >Approve this Addendum</button>
                      <h5 style={{color:'blue'}}>Addendum approved Status : {this.state.current_addendum_image.approved}  </h5>         
                      <img src={this.state.current_addendum_image.image} />
                      <hr></hr>
                      
                      <h3 style={{color:'red'}}>Solution image width:{this.state.current_solution_image.image_width} Pixels </h3>
                      <button  type="button" onClick={()=>{this.approve_addendum_or_solution_or_formula('solution', this.state.current_solution_image.id)}} >Approve this Solution</button>
                      <h5 style={{color:'blue'}}>Solution approved Status : {this.state.current_solution_image.approved}  </h5>
                      <img src={this.state.current_solution_image.image} />      
                      <hr></hr>
                      
                      <h3 style={{color:'red'}}>Formula image width:{this.state.current_formula_image.image_width} Pixels  </h3>
                      <button  type="button" onClick={()=>{this.approve_addendum_or_solution_or_formula('formula', this.state.current_formula_image.id)}} >Approve this Formula</button>
                      <h5 style={{color:'blue'}}>Formula approved Status : {this.state.current_formula_image.approved}  </h5>
                      <img src={this.state.current_formula_image.image} />
                     <hr></hr>
                     
                      
                               
                      <button  type="button" onClick={()=>{this.approve_question(this.state.current_viewed_question_in_modal.id)}} >Approve</button>
                      <button  type="button" onClick={()=>{this.delete_individual_question_detail(this.state.current_viewed_question_in_modal.id)}} >Delete</button>    
                      <button id='edit' type="button" onClick={(event)=>{this.handle_open_modal(event, this.state.current_viewed_question_in_modal.id)}}>Edit</button>                     
                                     
                      <button  type="button" onClick={this.handle_close_modal}>Exit</button>       
                       
                    </Modal>  
                    <Modal  isOpen={this.state.open_question_edit_modal_page} contentLabel="Minimal Modal Example">
        
                    <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                         
                      <Add_new_question edit={this.state.current_viewed_question_in_modal}/>  

                    <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                    </Modal>

                   


              </div>
     
    )
  }

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class View_all_addendum extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  list_of_addendum: [],
                  open_addendum_detail_modal_page:false,
                  current_viewed_addendum_in_modal:null,
                  total_addendums_available:0,
                  next_page:'',
                  current_page:'',
                  previous_page:'',

                  edit_addendum_data:{
                                    image:'',
                                    image_size:'',
                                    image_width:'',
                                    image_height:'',
                                 },

                  filter_data: {
                                  id:'',
                                  created_by:'',
                                  approved:''            
                                },               
                 
                };
  }

  componentDidMount(){
    this.get_info_of_addendum_from_db()



  }

  getBase64=(event)=> {

    var files = document.getElementById(event.target.id).files; 
    if(files[0].type=='image/jpeg' && files.length > 0){

            
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            
            

            
                        reader.onload = ()=> {   
                                  this.setState(state => (state.edit_addendum_data.image = reader.result, state))
                                  //this script below verifies the image dimension and should be placed inside reader.onload function
                                  //Initiate the JavaScript Image object.
                                  var image = new Image();
                                  //Set the Base64 string return from FileReader as source.
                                  image.src = reader.result;
                                  // this code below calculates the file size in kilobytes
                                  let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                                  let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                                  //this code above calculates the file size in kilobytes
                                  this.setState(state => (state.edit_addendum_data.image_size = image_size, state))
                                  //this code below calculates the image size and width
                                  image.onload = ()=> {
                                        var image_height = image.height;
                                        var image_width = image.width;

                                        if (image_width==1000){
                                          this.setState(state => (state.edit_addendum_data.image_width = image_width, state)) 
                                          this.setState(state => (state.edit_addendum_data.image_height = image_height, state))
                                        }
                                        else{
                                            this.setState(state => (state.edit_addendum_data.image_size = '', state))
                                            this.setState(state => (state.edit_addendum_data.image = '', state))
                                            alert('Image width Must be 1000 pixels')
                                        }

                                       
                                        
                                  }
                                  
                        }
              
               
                       
            reader.onerror = function (error) {
              alert('Error: ', error);
            };
            
    }
      
    else {
      alert('Wrong file type or no file selected')
    }         
}

  get_info_of_addendum_from_db=(control_url)=>{


              if (control_url===null){return}
              let url= control_url
            
              if(control_url == undefined){ url = base_url + 'list-of-all-addendum'+'?page=1'}
              

              this.setState({current_page:url})
            
              let token = localStorage.getItem("login_token")       
              let token_header= { Authorization :  'Token '+token} 
              let upload_data = this.state.filter_data

          axios({
            method: 'post',
            url: url,
            data:upload_data,
            headers: token_header
          })
          
            .then(res => {
              
              this.setState({list_of_addendum:res.data.results,
                            total_addendums_available: res.data.count,
                            next_page:res.data.next,
                            previous_page:res.data.previous,
                      });
              toast("Success")
             
              
            })

            .catch((error)=> {
                // handle error
                toast("Failed")
                console.log(error);       
              
            })

  }

  load_individual_addendum_detail=(id)=>{
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['addendum', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
          this.setState({current_viewed_addendum_in_modal:res.data});
          toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  edit_individual_addendum_detail=()=>{

    if(this.state.edit_addendum_data.image===""){alert('No image selected, Please select an image'); return}
    if (window.confirm("Are you sure you want to replace old image with new image?")===false) {return}
   
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['addendum', this.state.current_viewed_addendum_in_modal.id, this.state.edit_addendum_data];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'put',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
         
             
              this.setState({current_viewed_addendum_in_modal:res.data});
              toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  

  handle_open_modal=(id)=>{

    this.load_individual_addendum_detail(id)
 
    this.setState({open_addendum_detail_modal_page:true});      

  }
  handle_close_modal=()=>{
    this.get_info_of_addendum_from_db(this.state.current_page)

        this.setState({current_viewed_addendum_in_modal:null});
        this.setState({open_addendum_detail_modal_page:false});
        this.setState(state => (state.edit_addendum_data.image = '', state))
        this.setState(state => (state.edit_addendum_data.image_size = '', state))
        this.setState(state => (state.edit_addendum_data.image_width = '', state)) 
        this.setState(state => (state.edit_addendum_data.image_height = '', state))

  }
  
  current_paginated_page_information_and_serial_number_key=()=>{

    let total_number_of_pages = Math.ceil(this.state.total_addendums_available/pagination_amount)

    let current_page = this.state.current_page
    //this is to retrieve only numbers
   
    current_page = current_page.replace(/\D/g, "");
    //this is to remove 8000 port number while in local host
    current_page = current_page.replace(/8000/g, "");
    // this is to make default page in the the api page response is seen as page 1 in serial number
    if(current_page ===''){current_page='1'}
    let serial_number_key = (current_page-1) *pagination_amount

    let pagination_info = <div style={{width:'100%', margin:'20px 0 0px 55%'}}>
                              <p style={{float:'left'}}>Total Number of Addendums = {this.state.total_addendums_available }|| Page {current_page} of {total_number_of_pages} Page(s)  </p>
                              <button  style={{float:'left'}}type="button" onClick={()=>this.get_info_of_addendum_from_db(this.state.previous_page)}>Previous Page</button>      
                              <button style={{float:'left'}} type="button" onClick={()=>this.get_info_of_addendum_from_db(this.state.next_page)}>Next Page</button>
                            </div>
    return [pagination_info, serial_number_key]

  }
 
  approve_addendum=(id)=>{
    if (window.confirm("APPROVE this Addendum")===false) {return}

    let url = base_url ;
    url = url + 'approve-addendum-or-solution-or-formula'            
      
      let request_data=['addendum', id]
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 
     
      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
              this.get_info_of_addendum_from_db()                  
              toast("Success")
                  
        })

        .catch((error)=> {
            console.log(error)
            toast("Failed")
               
          
        })

  }

  handle_filter_Change=(event)=> {
   
    let new_filter_data= this.state.filter_data;
    
            let key;
            for (key in new_filter_data){

                  if(key===event.target.id){
                        new_filter_data[key]=event.target.value;
                                            
                  } 
            }
            this.setState({filter_data: new_filter_data});  

  }
  
  reset_filter=()=>{

    let new_filter_data= this.state.filter_data;
    
    let key;
        for (key in new_filter_data){

            new_filter_data[key]='';
                              
        }
        this.setState({filter_data: new_filter_data}); 
      
  }

  render() {

    let pagination_info_serial_number = this.current_paginated_page_information_and_serial_number_key()
    let addendum_image=null;

    if(this.state.current_viewed_addendum_in_modal !==null){
       addendum_image= <p> <img src={this.state.current_viewed_addendum_in_modal.image}  /></p>
    }
    return (

          
                  <div>
                    <h3>List of All Uploaded Addendums</h3>

                    <select id="approved"   onChange={this.handle_filter_Change} value={this.state.filter_data.approved}>
                          <option value="">All Status</option>
                          <option value="true">Approved</option>
                          <option value="false">Unapproved</option>
                    </select>
                  

                      <input id="created_by" onChange={this.handle_filter_Change} value={this.state.filter_data.created_by} placeholder="Created by"/>
                      <input id="id"  type="number"  onChange={this.handle_filter_Change} value={this.state.filter_data.id} placeholder="Question ID"/>
                     
                      <button type="button" id="open_addendum_modal" onClick={this.reset_filter}>Reset Filter</button>
                      <button type="button"  onClick={()=>this.get_info_of_addendum_from_db(base_url + 'list-of-all-addendum'+'?page=1')}>Filter Result</button>
                       

                    <br/>
                    {pagination_info_serial_number[0]}
                    <table class='table'>
                                    <tr>
                                          <th>S/N</th>
                                          <th>Addendum ID</th>
                                          <th></th>
                                          <th></th>
                                         
                                          <th>Approved</th>
                                          <th>Width</th>
                                          
                                          <th>Height</th>
                                          <th>Size(KB)</th>
                                          <th>Created By</th>
                                          
                                    </tr>
                                    {this.state.list_of_addendum.map((item, index) => ( 
                                    
                                    <tr>
                                          <td>{pagination_info_serial_number[1]+index+1}</td>
                                          <td>{item.id}</td>
                                          <td> <button  type="button"  onClick={()=>{this.handle_open_modal( item.id)}}>View</button></td>
                                         
                                          <td><button   onClick={()=>{this.approve_addendum(item.id)}} >Approve</button></td>
                                          <td>{item.approved}</td>
                                          <td>{item.image_width}</td>
                                          <td>{item.image_height}</td>
                                          
                                          <td>{item.image_size}</td>
                                          <td>{item.created_by}</td>
                                          
                                         
                                    </tr>   
                                  
                            ))}  
                           
                    </table>

                    {pagination_info_serial_number[0]}


                    <Modal id='jfh' isOpen={this.state.open_addendum_detail_modal_page} contentLabel="Minimal Modal Example">
        
                           <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                           
                           {addendum_image}

                            <hr></hr>  
                            <h3 style={{color:'red'}}>You Can Change Addendum Image Below</h3>                    
                            <h3>Upload New Image</h3>
                            <input id="upload_new_addendum" onChange={this.getBase64} type="file" accept="image/*"/>
                            <button id='upload_addendum' onClick={this.edit_individual_addendum_detail}  >Save New Image</button>
                              
                    </Modal>  
                       
              </div>
     

    )
  }

}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class View_all_solution extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  list_of_solution: [],
                  open_solution_detail_modal_page:false,
                  current_viewed_solution_in_modal:null,
                  total_solutions_available:0,
                  next_page:'',
                  current_page:'',
                  previous_page:'',

                  edit_solution_data:{
                                          image:'',
                                          image_size:'',
                                          image_width:'',
                                          image_height:'',
                                      },
                  filter_data: {
                                        id:'',
                                        created_by:'',
                                        approved:''            
                                      },    

                };
  }

  componentDidMount(){
    this.get_info_of_solution_from_db()



  }

  get_info_of_solution_from_db=(control_url)=>{


          if (control_url===null){return}
          let url= control_url
            
          if(control_url == undefined){ url = base_url + 'list-of-all-solution'+'?page=1'}

        this.setState({current_page:url})
      
        let token = localStorage.getItem("login_token")       
        let token_header= { Authorization :  'Token '+token} 
        let upload_data = this.state.filter_data

          axios({
            method: 'post',
            url: url,
            data:upload_data,
            headers: token_header
          })

            .then(res => {
              
              this.setState({list_of_solution:res.data.results,
                            total_solutions_available: res.data.count,
                            next_page:res.data.next,
                            previous_page:res.data.previous,
                      });
              toast("Success")
            
              
            })

            .catch((error)=> {
                // handle error
                toast("Failed")
                console.log(error);       
              
            })

  }

  getBase64=(event)=> {

    var files = document.getElementById(event.target.id).files; 
    if(files[0].type=='image/jpeg' && files.length > 0){

            
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            
            

            
                        reader.onload = ()=> {   
                                  this.setState(state => (state.edit_solution_data.image = reader.result, state))
                                  //this script below verifies the image dimension and should be placed inside reader.onload function
                                  //Initiate the JavaScript Image object.
                                  var image = new Image();
                                  //Set the Base64 string return from FileReader as source.
                                  image.src = reader.result;
                                  // this code below calculates the file size in kilobytes
                                  let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                                  let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                                  //this code above calculates the file size in kilobytes
                                  this.setState(state => (state.edit_solution_data.image_size = image_size, state))
                                  //this code below calculates the image size and width
                                  image.onload = ()=> {
                                        var image_height = image.height;
                                        var image_width = image.width;

                                        if (image_width==1000){
                                          this.setState(state => (state.edit_solution_data.image_width = image_width, state)) 
                                          this.setState(state => (state.edit_solution_data.image_height = image_height, state))
                                        }
                                        else{
                                            this.setState(state => (state.edit_solution_data.image_size = '', state))
                                            this.setState(state => (state.edit_solution_data.image = '', state))
                                            alert('Image width Must be 1000 pixels')
                                        }

                                       
                                        
                                  }
                                  
                        }
              
               
                       
            reader.onerror = function (error) {
              alert('Error: ', error);
            };
            
    }
      
    else {
      alert('Wrong file type or no file selected')
    }         
}



  load_individual_solution_detail=(id)=>{
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['solution', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
          this.setState({current_viewed_solution_in_modal:res.data});
          toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  edit_individual_solution_detail=()=>{

    if(this.state.edit_solution_data.image===""){alert('No image selected, Please select an image'); return}
    if (window.confirm("Are you sure you want to replace old image with new image?")===false) {return}
   
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['solution', this.state.current_viewed_solution_in_modal.id, this.state.edit_solution_data];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'put',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
         
             
              this.setState({current_viewed_solution_in_modal:res.data});
              toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  handle_open_modal=(id)=>{

    this.setState({open_solution_detail_modal_page:true});
    this.load_individual_solution_detail(id)


  }

  handle_close_modal=()=>{
    this.get_info_of_solution_from_db(this.state.current_page)

        this.setState({current_viewed_solution_in_modal:null});
        this.setState({open_solution_detail_modal_page:false});
        this.setState(state => (state.edit_solution_data.image = '', state))
        this.setState(state => (state.edit_solution_data.image_size = '', state))
        this.setState(state => (state.edit_solution_data.image_width = '', state)) 
        this.setState(state => (state.edit_solution_data.image_height = '', state))

  }
 
  
  current_paginated_page_information_and_serial_number_key=()=>{

    let total_number_of_pages = Math.ceil(this.state.total_solutions_available/pagination_amount)

    let current_page = this.state.current_page
    //this is to retrieve only numbers
   
    current_page = current_page.replace(/\D/g, "");
    //this is to remove 8000 port number while in local host
    current_page = current_page.replace(/8000/g, "");
    // this is to make default page in the the api page response is seen as page 1 in serial number
    if(current_page ===''){current_page='1'}
    let serial_number_key = (current_page-1) *pagination_amount

    let pagination_info = <div style={{width:'100%', margin:'20px 0 0px 55%'}}>
                              <p style={{float:'left'}}>Total Number of Solutions = {this.state.total_solutions_available }|| Page {current_page} of {total_number_of_pages} Page(s)  </p>
                              <button  style={{float:'left'}}type="button" onClick={()=>this.get_info_of_solution_from_db(this.state.previous_page)}>Previous Page</button>      
                              <button style={{float:'left'}} type="button" onClick={()=>this.get_info_of_solution_from_db(this.state.next_page)}>Next Page</button>
                            </div>
    return [pagination_info, serial_number_key]

  }  

  approve_solution=(id)=>{
    if (window.confirm("APPROVE this Solution?")===false) {return}

    let url = base_url ;
    url = url + 'approve-addendum-or-solution-or-formula'            
      
      let request_data=['solution', id]
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 
      
      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
                this.get_info_of_solution_from_db()                 
              toast("Success")
                  
        })

        .catch((error)=> {
            console.log(error)
            toast("Failed")
               
          
        })

  }

  handle_filter_Change=(event)=> {
   
    let new_filter_data= this.state.filter_data;
    
            let key;
            for (key in new_filter_data){

                  if(key===event.target.id){
                        new_filter_data[key]=event.target.value;
                                            
                  } 
            }
            this.setState({filter_data: new_filter_data});  

  }
  
  reset_filter=()=>{

    let new_filter_data= this.state.filter_data;
    
    let key;
        for (key in new_filter_data){

            new_filter_data[key]='';
                              
        }
        this.setState({filter_data: new_filter_data}); 
      
  }

  render() {
    let pagination_info_serial_number = this.current_paginated_page_information_and_serial_number_key()

    let solution_image=null;

    if(this.state.current_viewed_solution_in_modal !==null){
       solution_image= <p> <img src={this.state.current_viewed_solution_in_modal.image}  /></p>
    }
    return (

          
                  <div>
                    <h3>List of All Uploaded Solutions</h3>

                    <select id="approved"   onChange={this.handle_filter_Change} value={this.state.filter_data.approved}>
                          <option value="">All Status</option>
                          <option value="true">Approved</option>
                          <option value="false">Unapproved</option>
                    </select>
                  

                      <input id="created_by" onChange={this.handle_filter_Change} value={this.state.filter_data.created_by} placeholder="Created by"/>
                      <input id="id"  type="number"  onChange={this.handle_filter_Change} value={this.state.filter_data.id} placeholder="Question ID"/>
                     
                      <button type="button" id="open_addendum_modal" onClick={this.reset_filter}>Reset Filter</button>
                      <button type="button"  onClick={()=>this.get_info_of_solution_from_db(base_url + 'list-of-all-solution'+'?page=1')}>Filter Result</button>
                       
                    <br/>
                    {pagination_info_serial_number[0]}
                    <table class='table'>
                                    <tr>
                                          <th>S/N</th>
                                          <th>Solution ID</th>
                                          <th></th>
                                          
                                          <th></th>
                                          <th>Approved</th>
                                          <th>Width</th>
                                          
                                          <th>Height</th>
                                          <th>Size(KB)</th>
                                          <th>Created By</th>
                                          
                                    </tr>
                                    {this.state.list_of_solution.map((item, index) => ( 
                                    
                                    <tr>
                                          <td>{pagination_info_serial_number[1]+index+1}</td>
                                          <td>{item.id}</td>
                                          <td> <button  type="button" onClick={()=>{this.handle_open_modal(item.id)}}>View</button></td>
                                        
                                          <td><button   onClick={()=>{this.approve_solution(item.id)}} >Approve</button></td>
                                          <td>{item.approved}</td>
                                          <td>{item.image_width}</td>
                                          <td>{item.image_height}</td>
                                          
                                          <td>{item.image_size}</td>
                                          <td>{item.created_by}</td>
                                          
                                         
                                    </tr>   
                                  
                            ))}  
                           
                    </table>
                    {pagination_info_serial_number[0]}



                    <Modal  isOpen={this.state.open_solution_detail_modal_page} contentLabel="Minimal Modal Example">
        
                           <button  type="button" onClick={this.handle_close_modal}>Exit</button>

                           {solution_image}
                           <hr></hr>  
                            <h3 style={{color:'red'}}>You Can Change Solution Image Below</h3>                    
                            <h3>Upload New Image</h3>
                            <input id="upload_new_solution" onChange={this.getBase64} type="file" accept="image/*"/>
                            <button id='upload_solution' onClick={this.edit_individual_solution_detail}  >Save New Image</button>                     
                
                     </Modal>  
                       
              </div>
     

    )
  }

}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class View_all_formula extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  list_of_formula: [],
                  open_formula_detail_modal_page:false,
                  current_viewed_formula_in_modal:null,
                  total_formulas_available:0,
                  next_page:'',
                  current_page:'',
                  previous_page:'',

                  edit_formula_data:{
                                        image:'',
                                        image_size:'',
                                        image_width:'',
                                        image_height:'',
                                    },
                  filter_data: {
                                      id:'',
                                      created_by:'',
                                      approved:''            
                                    }, 


                };
  }

  componentDidMount(){
    this.get_info_of_formula_from_db()



  }

  get_info_of_formula_from_db=(control_url)=>{

            if (control_url===null){return}
            let url= control_url
            
            if(control_url == undefined){ url = base_url + 'list-of-all-formula'+'?page=1'}
              

          this.setState({current_page:url})
          let upload_data = this.state.filter_data
         
              let token = localStorage.getItem("login_token")       
              let token_header= { Authorization :  'Token '+token} 
    

          axios({
            method: 'post',
            url: url,
            data:upload_data,
            headers: token_header
          })
          
            .then(res => {
              
              this.setState({list_of_formula:res.data.results,
                total_formulas_available: res.data.count,
                next_page:res.data.next,
                previous_page:res.data.previous,
         });
              toast("Success")
             
              
            })

            .catch((error)=> {
                // handle error
                toast("Failed")
                console.log(error);       
              
            })

  }

  getBase64=(event)=> {

    var files = document.getElementById(event.target.id).files; 
    if(files[0].type=='image/jpeg' && files.length > 0){

            
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            
            

            
                        reader.onload = ()=> {   
                                  this.setState(state => (state.edit_formula_data.image = reader.result, state))
                                  //this script below verifies the image dimension and should be placed inside reader.onload function
                                  //Initiate the JavaScript Image object.
                                  var image = new Image();
                                  //Set the Base64 string return from FileReader as source.
                                  image.src = reader.result;
                                  // this code below calculates the file size in kilobytes
                                  let base64Length = image.src.length - (image.src.indexOf(',') + 1);
                                  let image_size = ((base64Length * 0.75)/1024).toFixed(2) ;
                                  //this code above calculates the file size in kilobytes
                                  this.setState(state => (state.edit_formula_data.image_size = image_size, state))
                                  //this code below calculates the image size and width
                                  image.onload = ()=> {
                                        var image_height = image.height;
                                        var image_width = image.width;

                                        if (image_width==1000){
                                          this.setState(state => (state.edit_formula_data.image_width = image_width, state)) 
                                          this.setState(state => (state.edit_formula_data.image_height = image_height, state))
                                        }
                                        else{
                                            this.setState(state => (state.edit_formula_data.image_size = '', state))
                                            this.setState(state => (state.edit_formula_data.image = '', state))
                                            alert('Image width Must be 1000 pixels')
                                        }

                                       
                                        
                                  }
                                  
                        }
              
               
                       
            reader.onerror = function (error) {
              alert('Error: ', error);
            };
            
    }
      
    else {
      alert('Wrong file type or no file selected')
    }         
  }

  load_individual_formula_detail=(id)=>{
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['formula', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
          this.setState({current_viewed_formula_in_modal:res.data});
          toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

  edit_individual_formula_detail=()=>{

    if(this.state.edit_formula_data.image===""){alert('No image selected, Please select an image'); return}
    if (window.confirm("Are you sure you want to replace old image with new image?")===false) {return}
   
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['formula', this.state.current_viewed_formula_in_modal.id, this.state.edit_formula_data];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'put',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
         
             
              this.setState({current_viewed_formula_in_modal:res.data});
              toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }


  handle_open_modal=(id)=>{

    this.setState({open_formula_detail_modal_page:true});
    this.load_individual_formula_detail(id)


  }

  handle_close_modal=()=>{
    this.get_info_of_formula_from_db(this.state.current_page)

        this.setState({current_viewed_formula_in_modal:null});
        this.setState({open_formula_detail_modal_page:false});
        this.setState(state => (state.edit_formula_data.image = '', state))
        this.setState(state => (state.edit_formula_data.image_size = '', state))
        this.setState(state => (state.edit_formula_data.image_width = '', state)) 
        this.setState(state => (state.edit_formula_data.image_height = '', state))

  }
 
  
  current_paginated_page_information_and_serial_number_key=()=>{

    let total_number_of_pages = Math.ceil(this.state.total_formulas_available/20)

    let current_page = this.state.current_page
    //this is to retrieve only numbers
   
    current_page = current_page.replace(/\D/g, "");
    //this is to remove 8000 port number while in local host
    current_page = current_page.replace(/8000/g, "");
    // this is to make default page in the the api page response is seen as page 1 in serial number
    if(current_page ===''){current_page='1'}
    let serial_number_key = (current_page-1) *20

    let pagination_info = <div style={{width:'100%', margin:'20px 0 0px 55%'}}>
                              <p style={{float:'left'}}>Total Number of Formulas = {this.state.total_formulas_available }|| Page {current_page} of {total_number_of_pages} Page(s)  </p>
                              <button  style={{float:'left'}}type="button" onClick={()=>this.get_info_of_formula_from_db(this.state.previous_page)}>Previous Page</button>      
                              <button style={{float:'left'}} type="button" onClick={()=>this.get_info_of_formula_from_db(this.state.next_page)}>Next Page</button>
                            </div>
    return [pagination_info, serial_number_key]

  }    

  approve_formula=(id)=>{
    if (window.confirm("APPROVE this Formula?")===false) {return}

    let url = base_url ;
    url = url + 'approve-addendum-or-solution-or-formula'            
      
      let request_data=['formula', id]
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 
      
      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
               this.get_info_of_formula_from_db()
                  
              toast("Success")
                  
        })

        .catch((error)=> {
            console.log(error)
            toast("Failed")
               
          
        })

  }

  handle_filter_Change=(event)=> {
   
    let new_filter_data= this.state.filter_data;
    
            let key;
            for (key in new_filter_data){

                  if(key===event.target.id){
                        new_filter_data[key]=event.target.value;
                                            
                  } 
            }
            this.setState({filter_data: new_filter_data});  

  }
  
  reset_filter=()=>{

    let new_filter_data= this.state.filter_data;
    
    let key;
        for (key in new_filter_data){

            new_filter_data[key]='';
                              
        }
        this.setState({filter_data: new_filter_data}); 
      
  }

  render() {
    let pagination_info_serial_number = this.current_paginated_page_information_and_serial_number_key()

    let formula_image=null;

    if(this.state.current_viewed_formula_in_modal !==null){
       formula_image= <p> <img src={this.state.current_viewed_formula_in_modal.image}  /></p>
    }
    return (

          
                  <div>
                    <h3>List of All Uploaded Formula Sheets</h3>

                    <select id="approved"   onChange={this.handle_filter_Change} value={this.state.filter_data.approved}>
                          <option value="">All Status</option>
                          <option value="true">Approved</option>
                          <option value="false">Unapproved</option>
                    </select>
                  

                      <input id="created_by" onChange={this.handle_filter_Change} value={this.state.filter_data.created_by} placeholder="Created by"/>
                      <input id="id"  type="number"  onChange={this.handle_filter_Change} value={this.state.filter_data.id} placeholder="Question ID"/>
                     
                      <button type="button" id="open_addendum_modal" onClick={this.reset_filter}>Reset Filter</button>
                      <button type="button"  onClick={()=>this.get_info_of_formula_from_db(base_url + 'list-of-all-formula'+'?page=1')}>Filter Result</button>
                       
                    <br/>
                    {pagination_info_serial_number[0]}
                    <table class='table'>
                                    <tr>
                                          <th>S/N</th>
                                          <th>Formula ID</th>
                                          <th></th>
                                          <th></th>
                                        
                                          <th>Approved</th>
                                          <th>Width</th>
                                          
                                          <th>Height</th>
                                          <th>Size(KB)</th>
                                          <th>Created By</th>
                                          
                                    </tr>
                                    {this.state.list_of_formula.map((item, index) => ( 
                                    
                                    <tr>
                                          <td>{pagination_info_serial_number[1]+index+1}</td>
                                          <td>{item.id}</td>
                                          <td> <button  type="button" onClick={()=>{this.handle_open_modal(item.id)}}>View</button></td>
                                         
                                          <td><button   onClick={()=>{this.approve_formula(item.id)}} >Approve</button></td>
                                          <td>{item.approved}</td>
                                          <td>{item.image_width}</td>
                                          <td>{item.image_height}</td>
                                          
                                          <td>{item.image_size}</td>
                                          <td>{item.created_by}</td>
                                          
                                         
                                    </tr>   
                                  
                            ))}  
                           
                    </table>
                    {pagination_info_serial_number[0]}



                    <Modal  isOpen={this.state.open_formula_detail_modal_page} contentLabel="Minimal Modal Example">
        
                           <button  type="button" onClick={this.handle_close_modal}>Exit</button>

                           {formula_image}
                           <hr></hr>  
                            <h3 style={{color:'red'}}>You Can Change This Formula Image Below</h3>                    
                            <h3>Upload New Image</h3>
                            <input id="upload_new_solution" onChange={this.getBase64} type="file" accept="image/*"/>
                            <button id='upload_solution' onClick={this.edit_individual_formula_detail}  >Save New Image</button>                         
                
                     </Modal>  
                       
              </div>
     

    )
  }

}


class Add_new_news extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                
                  button_disable:false,
                  has_set_edit:false,

                  form_data:{
                              id:'',
                              news_subject:'',
                              news_body:'',

                             }

                };
  }

  componentDidMount(){

       


  }


  handle_news_submit=(event)=> {
    this.setState({ button_disable: true })        
    let url = base_url;
        url= url + 'add-new-news'
    if(this.state.has_set_edit===true){url= base_url+'edit-news'}

    let token = localStorage.getItem("login_token")       
    let token_header= { Authorization :  'Token '+token} 

    
    axios({
      method: 'post',
      url: url,
      data:this.state.form_data,
      headers:token_header
    })
    
      .then(res => {


            if(this.state.has_set_edit===false){

                  let new_form_data = this.state.form_data
                  new_form_data.news_subject =''
                  new_form_data.news_body = ''
                  this.setState({ form_data: new_form_data })
            }
         
             this.setState({ button_disable: false })
             toast("Saved Sucessfully") 
      })

      .catch((error)=> {
          // handle error
          console.log(error);
          this.setState({ button_disable: false }) 
          toast("Failure")
         
      })

  }


  handleChange=(event)=> {
   
              let new_form_data= this.state.form_data;
              let key = event.target.id
              new_form_data[key]=event.target.value;
              this.setState({form_data: new_form_data});                                   

  }

  set_edit_values=()=>{
   
      if(typeof this.props.edit !== "undefined"  && this.state.has_set_edit===false){
       
       
        this.setState({ has_set_edit: true })
        this.setState({ form_data: this.props.edit })
       
      }


  }

  render() {
    // the set timeout below is needed, because its throwing errors if it is set instantaneously
    setTimeout(this.set_edit_values, 200)
    let button = "Create News"
    let heading = "Create a News"
    if( this.state.has_set_edit=== true){
      button = "Edit News"
      heading = "Edit News: " + this.state.form_data.news_subject
    }
    
    return (

          
                  <div>
                    <h2>{heading}</h2>
                  <form className='question_form_div'>
                      
                      
                        <label htmlFor="addendum_id">Subject:</label>
                        <input id="news_subject" className='questionform' type="text" value="" onChange={this.handleChange} value={this.state.form_data.news_subject} />
                      
                       
                    <br/>    
                      
                      <label htmlFor="solution_id">News Content:</label>
                      <br/>

                      <textarea rows="4" style={{width:'100%'}} id="news_body" onChange={this.handleChange} value={this.state.form_data.news_body}   >
                            
                      </textarea >
                     
                               
                      <br/>

                    
                    

                                 

                        <br/>
                        <button type="button" className='question_form_submit_button' onClick={this.handle_news_submit} disabled={this.state.button_disable} >{button}</button>
                    
                        
            </form>
                       
              </div>
     

    )
  }

}


class View_all_news extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
                  list_of_news: [],
                  open_news_detail_modal_page:false,
                  open_news_edit_modal_page:false,
                  current_viewed_news_in_modal:{

                      id:''

                  },
                  total_news_available:0,
                  next_page:'',
                  current_page:'',
                  previous_page:'',

                
                  filter_data: {
                                  id:'',
                                  created_by:'',
                                  approved:''            
                                },               
                 
                };
  }

  componentDidMount(){
    this.get_info_of_news_from_db()



  }

 

  get_info_of_news_from_db=(control_url)=>{


              if (control_url===null){return}
              let url= control_url
            
              if(control_url == undefined){ url = base_url + 'list-of-all-news'+'?page=1'}
              

              this.setState({current_page:url})
            
              let token = localStorage.getItem("login_token")       
              let token_header= { Authorization :  'Token '+token} 
              let upload_data = this.state.filter_data

          axios({
            method: 'post',
            url: url,
            data:upload_data,
            headers: token_header
          })
          
            .then(res => {
              
              this.setState({list_of_news:res.data.results,
                            total_news_available: res.data.count,
                            next_page:res.data.next,
                            previous_page:res.data.previous,
                      });
              toast("Success")
             
              
            })

            .catch((error)=> {
                // handle error
                toast("Failed")
                console.log(error);       
              
            })

  }

  load_individual_news_detail=(id)=>{
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['news', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 


      axios({
        method: 'post',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
          
          this.setState({current_viewed_news_in_modal:res.data});
          toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }

 
  handle_open_modal=(event, id)=>{

    
          this.load_individual_news_detail(id)
          if (event.target.id === 'view'){
                    this.setState({open_news_detail_modal_page:true});      
          }

          if (event.target.id === 'edit'){
                 this.setState({open_news_edit_modal_page:true});      
           }


  }

  handle_close_modal=()=>{
    this.get_info_of_news_from_db(this.state.current_page)

        this.setState({current_viewed_news_in_modal:{id:''}});
        this.setState({open_news_detail_modal_page:false});
        this.setState({open_news_edit_modal_page:false});

  }
  
  current_paginated_page_information_and_serial_number_key=()=>{

    let total_number_of_pages = Math.ceil(this.state.total_news_available/pagination_amount)

    let current_page = this.state.current_page
    //this is to retrieve only numbers
   
    current_page = current_page.replace(/\D/g, "");
    //this is to remove 8000 port number while in local host
    current_page = current_page.replace(/8000/g, "");
    // this is to make default page in the the api page response is seen as page 1 in serial number
    if(current_page ===''){current_page='1'}
    let serial_number_key = (current_page-1) *pagination_amount

    let pagination_info = <div style={{width:'100%', margin:'20px 0 0px 55%'}}>
                              <p style={{float:'left'}}>Total Number of News Items = {this.state.total_news_available }|| Page {current_page} of {total_number_of_pages} Page(s)  </p>
                              <button  style={{float:'left'}}type="button" onClick={()=>this.get_info_of_news_from_db(this.state.previous_page)}>Previous Page</button>      
                              <button style={{float:'left'}} type="button" onClick={()=>this.get_info_of_news_from_db(this.state.next_page)}>Next Page</button>
                            </div>
    return [pagination_info, serial_number_key]

  }
 
  

  handle_filter_Change=(event)=> {
   
    let new_filter_data= this.state.filter_data;
    
            let key;
            for (key in new_filter_data){

                  if(key===event.target.id){
                        new_filter_data[key]=event.target.value;
                                            
                  } 
            }
            this.setState({filter_data: new_filter_data});  

  }
  
  reset_filter=()=>{

    let new_filter_data= this.state.filter_data;
    
    let key;
        for (key in new_filter_data){

            new_filter_data[key]='';
                              
        }
        this.setState({filter_data: new_filter_data}); 
      
  }

  delete_individual_news_detail=(id)=>{

    if (window.confirm("DELETE this question?")===false) {return}
    let url = base_url ;
    url = url + 'individual-question-or-addendum-or-solution-detail'            
      
      let request_data=['news', id];
      let token = localStorage.getItem("login_token")       
      let token_header= { Authorization :  'Token '+token} 

      axios({
        method: 'delete',
        url: url,
        headers: token_header,
        data: request_data
      })

        .then(res => {
         
              let x=0
              let old_list = this.state.list_of_news;
                    
                    while (x < old_list.length){
        
                          if (old_list[x].id ==id ){  
                              
                                old_list.splice(x, 1)
                          
                          }
                          x=x+1;
                    }
                    
              this.setState({list_of_news:old_list}); 
              this.handle_close_modal()

              toast("Success")
        
          
        })

        .catch((error)=> {
            // handle error
            toast("Failed")
            console.log(error);       
          
        })

  }


  render() {

    let pagination_info_serial_number = this.current_paginated_page_information_and_serial_number_key()
    
    let modal_detail='';

    if(this.state.current_viewed_news_in_modal.id !==''){
        modal_detail=     <div>
        
        <p>News ID : {this.state.current_viewed_news_in_modal.id}</p>
        <p>News Title : {this.state.current_viewed_news_in_modal.news_subject}</p>
        <p>News Content : {this.state.current_viewed_news_in_modal.news_body}</p>
        <p>Last Updated At : {this.state.current_viewed_news_in_modal.last_updated_at}</p>
        <p>Last Modified By : {this.state.current_viewed_news_in_modal.created_by}</p>
                        </div>
    }
   
    return (

          
                  <div>
                    <h3>All News Items</h3>

                    {pagination_info_serial_number[0]}

                    <table class='table'>
                                    <tr>
                                          <th>S/N</th>
                                          <th>News ID</th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th>Title</th>
                                          <th>Last Updated By</th>
                                          <th>Last updated At</th>
                                          
                                    </tr>
                                    {this.state.list_of_news.map((item, index) => ( 
                                    
                                    <tr>
                                          <td>{pagination_info_serial_number[1]+index+1}</td>
                                          <td>{item.id}</td>
                                          <td> <button  type="button"  id="view" onClick={(event)=>{this.handle_open_modal( event, item.id)}}>View</button></td>
                                         
                                          <td><button   type="button"  id="edit" onClick={(event)=>{this.handle_open_modal( event, item.id)}} >Edit</button></td>
                                          <td><button  type="button" onClick={()=>this.delete_individual_news_detail(item.id)}>Delete</button></td>
                                          <td>{item.news_subject}</td>
                                          
                                          <td>{item.created_by}</td>
                                          <td>{item.last_updated_at}</td>
                                         
                                    </tr>   
                                  
                            ))}  
                           
                    </table>

                    {pagination_info_serial_number[0]}


                    <Modal id='jfh' isOpen={this.state.open_news_detail_modal_page} contentLabel="Minimal Modal Example">
                            <button  type="button" onClick={()=>this.delete_individual_news_detail(this.state.current_viewed_news_in_modal.id)}>Delete</button>
                           <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                           
                           {modal_detail}

                            
                              
                    </Modal>  


                    <Modal  isOpen={this.state.open_news_edit_modal_page} contentLabel="Minimal Modal Example">
        
                         
                          <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                              
                            <Add_new_news edit={this.state.current_viewed_news_in_modal}/>  

                          <button  type="button" onClick={this.handle_close_modal}>Exit</button>
                  </Modal>






                       
              </div>
     

    )
  }

}


///////////////connecting Login to store

const mapStateToLogin_Props = state => {
  return{                      
             user_account: state.user_account,

         }
};

const mapDispatchToLogin_Props = dispatch => ({  

  save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
     
     
});
let ConnectLogin  = connect (mapStateToLogin_Props, mapDispatchToLogin_Props)(Login);



///////////////connecting HeaderSection to store

const mapStateToHeaderSection_Props = state => {
  return{                      
             user_account: state.user_account,

         }
};

const mapDispatchToHeaderSection_Props = dispatch => ({  

  save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
     
     
});
let ConnectHeaderSection  = connect (mapStateToHeaderSection_Props, mapDispatchToHeaderSection_Props)(HeaderSection);




///////////////connecting App to store
const mapStateToAppProps = state => {
  return{
            user_account: state.user_account, 
           
         }
};

const mapDispatchToAppProps = dispatch => ({  
      save_user_account_to_store : (data) => dispatch(save_user_account_to_store(data)),
     
});


export default connect(mapStateToAppProps, mapDispatchToAppProps)(App);